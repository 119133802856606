import styled from "styled-components";
import aboutMe1 from "./aboutme1-min.jpg";
import GoToTop from "../common/utilities";
import backgroundImage from "./detatailedbackground.jpeg"

const ArticleContainer = styled.div`
    
`;
const ArticleImageContainer = styled.div`
    min-width: 100%;
    height: 700px;
    overflow: hidden; 
    display: inline-block;

    @media(max-width: 800px) {
        height: 250px;
    }
`;
const ArticleImage = styled.img`
    width: 100%;
    height: 100%;
    transition: all .2s ease;
    vertical-align: middle;
    object-fit: cover;
`;
const ArticleContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding-left: 200px;
    padding-right: 200px;
    padding-top: 30px;
    padding-bottom: 100px;

    @media(max-width: 800px) {
        padding-left: 0;
        padding-right: 0;
        padding-top: 20px;
        padding-bottom: 20px;
    } 
`;
const ArticleDescriptionContainer = styled.div`
    & img {
        height: 400px;
        border-radius: 50%;
        @media(max-width: 1200px) {
            height: 350px;
        }
        @media(max-width: 800px) {
            width: 300px;
            height: 300px;
        }
    }
    & div {
        justify-content: center;
        display: flex;
        max-width: 800px;
        flex-direction: column;
        padding-left: 50px;
        padding-right: 50px;
        line-height: 22px;

        & h2:not(:first-child) {
            margin-top: 20px;
        }
        & h2 {
            margin-bottom: 15px;
        }
        @media(max-width: 800px) {
            font-size: 13px;
            padding-bottom: 25px;
        }
    }
    justify-content: center;
    display: flex;
    flex-direction: row;

    @media(max-width: 800px) {
        align-items: center;
        flex-direction: column-reverse;
    }
`;

const ArticleDescriptionContent = ({title, children}) => {
    return (
        <ArticleDescriptionContainer>
            <div>
                <h2>{title}</h2>
                {children}
            </div>
        </ArticleDescriptionContainer>
    );
};


export default function Blog1() {
    return (
        <ArticleContainer>
            <ArticleImageContainer>
                <ArticleImage src={backgroundImage} />
            </ArticleImageContainer>
            <ArticleContentContainer>
                <ArticleDescriptionContent title="Dezvolta picioarele cu 3 PUNCTE CHEIE">
                    <p>
                    <p><span>🚨Stiu ca foarte multi va straduiti fara rezultate bune, in a creste picioare mai serios, asa ca voi impartii cateva informatii care m-au ajutat pe mine personal, sa trec la urmatorul nivel. </span><br /><br /><span>Sunt persoana care avea antebrate in loc de picioare, cum se vede si in video.. cand am schimbat si aplicat cele 3 lucruri de care va vorbesc astazi, schimbarile si progresul au inceput sa tot apara:</span><br /><br /><span>CONTROL</span><br /><span>CURSA MISCARII</span><br /><span>CONTINUITATE</span><br /><br /><span>👉🏼 Controleaza fiecare cm din miscarea executata! Nu pot sa accentuez destul, cat de important este..effectiv nu prea folosesti muschii picioarelor in caz contrar 🤷🏼&zwj;♂️</span><br /><br /><span>👉🏼 Daca nu executi miscarea pe intreaga cursa a sa, cum altfel sa stimulati toate fibrele de pe coapse / fese / femurali? Daca nu stimulati / folositi fibrele alea, pai nu cresc evident 🤷🏼&zwj;♂️</span><br /><br /><span>👉🏼 O sa aveti nevoie de antrenament si de tarie de caracter pentru asta.. chiar nu e usor, si te scoate maxim din zona de confort, sa poti sa stai cu o greutate foarte mare in spate, sa executi constant repetari cand simti ca te sufoci, ca te dor picioarele de mori, ca iti sare inima in piept, si tot corpul iti cere sa opresti⚠️..dar asta impreuna cu executia decentq cum am descris si mai sus o sa faca TOATA DIFERENTA! 🤞🏼</span><br /><span>🔞Totusi nu este pentru cei slabi de ingeri..</span></p>
                    </p>
                </ArticleDescriptionContent>

            </ArticleContentContainer>
            <GoToTop/>
        </ArticleContainer>
    );
};