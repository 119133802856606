import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import './index.css';
import Header, {OrderConfirmedHeader, SimpleHeader} from "./header/Header";
import Jumbotron from "./jumbotron/Jumbotron";
import About from "./about/About";
import Services from "./services/Services";
import Results from "./results/Results";
import Footer from "./footer/Footer";
import DetailedAboutMe from "./about/DetailedAboutMe";
import DetailedResults from "./results/DetailedResults";
import TermsAndConditions from './terms/TermsAndConditions';
import styled from "styled-components";
import Cookies from './cookies/Cookies';
import Vlogs from './vlog/Vlog';
import Blog1 from './blog/Blog1';
import OnlineCoaching from './onlinecoaching/OnlineCoaching';
import Challenges from './challenges/challenges';
import ChallengesSubscribed from './challenges/ChallengesSubscribed';
import Confidentialitaty from './terms/Confidentialitaty';

const Container = styled.div.attrs(props => ({
    id: "base-container"
}))`
    min-height: 100%;
    display: flex;
    flex-direction: column;
`

const MainView = () => {
    return (
        <Container>
            <Header/>
            <Jumbotron/>
            <About/>
            <Services/>
            <Results/>
            <Vlogs/>
            <Footer/>
        </Container>
    );
};

const DetailedAboutMeView = () => {
    return (
        <Container>
            <SimpleHeader/>
            <DetailedAboutMe/>
            <Footer/>
        </Container>
    );
};

const Blog1View = () => {
    return (
        <Container>
            <SimpleHeader/>
            <Blog1/>
            <Footer/>
        </Container>
    );
};

const OnlineCoachingView = () => {
    return (
        <Container>
            <SimpleHeader/>
            <OnlineCoaching/>
            <Footer/>
        </Container>
    );
};

const DetailedResultsView = () => {
    return (
        <Container>
            <SimpleHeader/>
            <DetailedResults/>
            <Footer/>
        </Container>
    );
};
const TermsAndCondtionsView = () => {
    return (
        <Container>
            <SimpleHeader/>
            <TermsAndConditions/>
            <Footer/>
        </Container>
    );
};
const ConfidentialitatyView = () => {
    return (
        <Container>
            <SimpleHeader/>
            <Confidentialitaty/>
            <Footer/>
        </Container>
    );
};
const CookiesView = () => {
    return (
        <Container>
            <SimpleHeader/>
            <Cookies/>
            <Footer/>
        </Container>
    );
};
const ChallengesView = () => {
    return (
        <Container>
            <SimpleHeader/>
            <Challenges/>
            <Footer/>
        </Container>
    );
};
const ChallengesSubscribedView = () => {
    return (
        <Container>
            <SimpleHeader/>
            <ChallengesSubscribed/>
            <Footer/>
        </Container>
    );
};

ReactDOM.render((
    <BrowserRouter>
        <Routes>
            <Route exact path={'/'} element={<MainView/>}/>
            <Route path={'/about'} element={<DetailedAboutMeView/>}/>
            <Route path={'/results'} element={<DetailedResultsView/>}/>
            <Route path={'/termenii'} element={<TermsAndCondtionsView/>}/>
            <Route path={'/politconf'} element={<ConfidentialitatyView/>}/>
            <Route path={'/cookies'} element={<CookiesView/>}/>
            <Route path={'/blog1'} element={<Blog1View/>}/> 
            <Route path={'/onlineCoaching'} element={<OnlineCoachingView/>}/> 
            <Route path={'/challenges'} element={<ChallengesView/>}/> 
            <Route path={'/subscribed'} element={<ChallengesSubscribedView/>}/> 
        </Routes>
    </BrowserRouter>
), document.getElementById('root'));
