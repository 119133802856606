import React from "react";
import GoToTop from "../common/utilities";

export default function TermsAndConditions() {
    return (
       <div>
<p dir="ltr">
    I. Descriere generală.
</p>
<p dir="ltr">
    În prezenta secțiune vă vom prezenta termenii și condițiile pentru
    utilizarea serviciilor nostre. Cu titlu general, termenii și condițiile se
    vor aplica direct și indirect tuturor utilizatorilor serviciilor, prin
    distribuiri online pe orice platformă de socializare ori prin orice alte
    intermedii aferente. Prin accesarea, utilizarea sau căutarea site-ului
    https://antalszabyfitness.com sau a oricărei aplicații pe oricare dintre
    platformele pe care se distribuie ori prin efectuarea unei comenzi,
    confirmați că ați citit, înțeles și ați fost de acord cu termenii și
    condițiile prezentate în secțiunea prezentă, cu politica noastră privind
    modulele cookie, precum și cu politica de confidențialitate a site-ului
    (denumite în continuare în mod colectiv „Politicile de protecție a
    datelor”) stabilită în conformitate cu dispozițiile legii nr. 190/2018.
</p>
<p dir="ltr">
    Termene și definiții.
</p>
<p dir="ltr">
    În sensul prezentei secțiuni termenii și expresiile de mai jos au
    următoarele semnificații:
</p>
<p dir="ltr">
    a) Programe de antrenament - orice program de antrenament prezent Muscle
    Up! sau Strong &amp; Sexy, Home Workout, precum și orice alt program
    elaborat în viitor și încadrat ca atare, pus la dispoziția clienților
    noștri pe platforma noastră.
</p>
<p dir="ltr">
    b) Servicii - orice serviciu regăsit pe site: Personal Training, Online
    Choaching sau Nutrition, precum și orice alt serviciu viitor elaborat și
    încadrat ca atare, pus la dispoziția clienților noștri prin intermediul
    platformei.
</p>
<p dir="ltr">
    c) Produs - orice alt serviciu care nu se încadrează într-una dintre
    secțiunile aferente programelor de antrenament sau serviciilor mele confrom
    lit. b) sau c).
</p>
<p dir="ltr">
    c) Client - orice persoană fizică sau juridică care achiziționeză unul
    dintre programele noastre de antrenament sau unul dintre serviciile noastre
    oferite contra cost.
</p>
<p dir="ltr">
    d) Platofmă sau site - site-ul https://antalszabyfitness.com.
</p>
<p dir="ltr">
    II. Cum puteți utiliza platforma?
</p>
<p dir="ltr">
    Platforma este adresată atât membrilor, cât și celor care nu sunt membri ai
    serviciilor noastre, fiind puse la dispoziția utilizatorilor prin
    intermediul site-ului nostru.
</p>
<p dir="ltr">
    Am dezvoltat diferite programe și servicii, disponibile prin intermediul
    platformei noastre și care pot fi achiziționate online.
</p>
<p dir="ltr">
    Vizitatorii website-ului nostru pot beneficia de informații generale despre
    programele și serviciile noastre puse la dispoziție, precum și de un
    portofoliu detaliat cu imagini în care sunt prezentate rezultatele obținute
    de către clineții noștri, pentru a vă ajuta să vă decideți dacă serviciile
    sunt potrivite nevoilor dumneavoastră de antrenament. În cazul în care
    doriți mai multe informații puteți lua legătura cu noi printr-unul dintre
    mijloacele puse la dispoziție pe site (număr de telefon sau e-mail).
</p>
<p dir="ltr">
    Pentru a beneficia de serviciile, programele sau produsele noastre
    utilizatorii nu trebuie să se înregistreze pe website-ul nostru.
</p>
<p dir="ltr">
    De asemenea, platforma Antal Szaby Fitness oferă o bibliotecă online cu
    diferite tipuri de exerciții, de unde le puteți alege pe cele potrivite
    condiției, abilităților și obiectivelor dumneavoastră. Vă recomandăm să
    solicitați sfatul medicului înainte de a începe orice exercițiu furnizat
    prin intermediul serviciilor noastre, dacă aveți o afecțiune medicală sau o
    vătămare de orice fel care ar putea fi afectată sau agravată de exerciții
    fizice.
</p>
<p dir="ltr">
    Serviciile vor fi disponibile membrilor noștri la ore/date…
</p>
<p dir="ltr">
    În general, serviciile noastre de atrenament vă oferă următoarele îndrumări
    privind efectuarea și finalizarea anumitor exerciții în siguranță și
    eficient. Cu toate acestea, sunteți singurul responsabil pentru asigurarea
    unei executări adecvate a exercițiilor, conform îndrumărilor pe care le
    oferim și într-un mod sigur pentru sănătatea dumneavoastră. Nu vom fi
    răspunzători pentru nicio vătămare pe care o puteți provoca pentru
    dumneavoastră sau pentru alții, dacă nu respectați îndrumările furnizate
    sau dacă acționați neglijent în timpul exercițiilor. Pentru mai multe
    detalii despre serviciile și programele noastre consultați pagina noastră
    https://antalszabyfitness.com.
</p>
<p dir="ltr">
    Prin utilizarea acestui site web și/sau plasarea oricărei comenzi prin
    intermediul acestuia, vă obligă:
</p>
<p dir="ltr">
    1. Să folosiți acest Site web exclusiv pentru a transmite întrebări
    legitime sau a efectua operațiuni legitime.
</p>
<p dir="ltr">
    2. Să nu efectuați nicio operațiune falsă sau frauduloasă. În cazul în care
    avem motive întemeiate să credem că ați efectuat o astfel de operațiune,
    vom avea dreptul să o anulăm și să informăm în mod corespunzator
    autoritațile competente.
</p>
<p dir="ltr">
    3. Să ne furnizați o adresă de e-mail, o adresă poștală și/sau alte date de
    contact corecte și exacte. În egală masură, consimțiți să folosim aceste
    informații pentru a vă contacta în eventualitatea în care acest lucru este
    necesar (vedeți Politica noastră de confidențialitate).
</p>
<p dir="ltr">
    Dacă nu ne furnizați toate informațiile de care avem nevoie, este posibil
    să nu putem sa finalizăm comanda dumneavoastră. Prin plasarea unei comenzi
    prin intermediul Site-ului web, declarați că aveți capacitatea legală de a
    încheia contracte ori că sunteți abilitat în această privință.
</p>
<p dir="ltr">
    II. Modificări ale website-ului, software-ului, ale serviciilor și
    programelor noastre.
</p>
<p dir="ltr">
    Rezervăm dreptul de a:
</p>
<p dir="ltr">
    a) modifica sau şterge (temporar sau permanent) website-ul sau orice parte
    a acestuia fără a anunţa, iar dvs. acceptaţi faptul că nu suntem
    responsabili pentru asemenea modificări sau ştergeri;
</p>
<p dir="ltr">
    b) modifica, şterge sau întrerupe orice software, serviciu sau promoţie
    (inclusiv, dar fără a se limita la, orice preverderi, părţi, licenţe,
    preţuri), aşa cum sunt promovate pe acest website în orice moment, fără a
    anunţa, iar dvs. acceptaţi faptul că nu suntem responsabili pentru asemenea
    modificări sau ştergeri;
</p>
<p dir="ltr">
    c) modifica sau întrerupe orice voucher promoţional de reducere sau cod de
    cupon de reducere în orice moment cu anunţ prealabil, iar dvs. acceptaţi
    faptul că nu suntem responsabili pentru asemenea modificări sau ştergeri.
</p>
<p dir="ltr">
    III. Linkuri către website-urile terţilor
</p>
<p dir="ltr">
    Website-ul poate include link-uri către website-urile terţilor, care sunt
    controlate şi conduse de alţii. Orice link spre un alt website nu este o
    recomandare a acestui website, iar dvs. luaţi la cunoştinţă acest fapt şi
    sunteţi conştienţi de faptul că nu ne asumăm răspunderea pentru conținutul
    regăsit în cadrul acestora și nici în legătură cu fișierele descărcate care
    pot conține viruși, pentru alte elemente ce pot aduce o atingere datelor
    utilizatorilor sau pentru disponibilitatea acestora. Este posibil să fie
    regăsite link-uri de legătură directă cu platforma pe alte site-uri sau
    rețele de socializare, caz în care nu ne asumăm răspunderea pentru modul în
    care au fost postate pe site-ul sau rețeaua respectivă, iar ajungerea pe
    platformă prin intermediul acestora reprezintă o facultate pentru
    utilizator, acesta nefiind niciodată împiedicat să acceseze direct
    platforma
</p>
<p dir="ltr">
    IV. Politica de confidențialitate.
</p>
<p dir="ltr">
    Pentru o cât mai bună colaborare, politica de confidențialitate este foarte
    importantă, de aceea vă rugăm să acordați o atenție sporită celor stipulate
    în prezentul document.
</p>
<p dir="ltr">
    V. Politica generală de vânzare.
</p>
<p dir="ltr">
    V.1. Disponibilitatea produselor.
</p>
<p dir="ltr">
    Prin intermediul platformei noastre, secțiunea Programe, se pune la
    dispoziția cumpărătorului posibilitatea de a achiziționa un produs online
    care constă într-un program de antrenament online. Fiecare program are
    specificațiile lui proprii și presupune anumite activități care vor fi
    detaliate în materialul care se primește contra prețului plătit. Toate
    comenzile de produse depind de disponibilitatea produselor respective în
    stocurile noastre. În acest sens, în eventualitatea în care apar
    dificultăți de aprovizionare sau dacă produsele nu se mai află în stoc, ne
    rezervăm dreptul de a vă informa cu privire la produse înlocuitoare de
    calitate și valoare egale sau superioare, pe care le puteți comanda. Dacă
    nu doriți să comandați aceste produse înlocuitoare și dacă am primit deja
    plata aferentă produselor comandate, vă vom rambursa integral orice sume de
    bani pe care le-ați plătit.
</p>
<p dir="ltr">
    V.2. Preț și TVA.
</p>
<p dir="ltr">
    Costul fiecărui program este exprimat în moneda națională, RON. Prețul este
    corespunzător momentului la care se face comanda. Prețurile vor fi diferite
    raportat la fiecare program.
</p>
<p dir="ltr">
    Valoarea fiecărui program afișat pe site este valoarea brută și include
    TVA, cu excepția cazului în care există o eroare evidentă.
</p>
<p dir="ltr">
    Cu toate că avem grijă să ne asigurăm că toate prețurile indicate pe
    Site-ul nostru sunt corecte, pot apărea erori. În condițiile în care apar
    erori sau există probleme sau disfuncționalități cu privire la site,
    sistemul de plată sau mecanismul de înregistrare, vânzătorul va informa de
    îndată persoana cu privire la care se referă eroarea. De asemenea, vă vom
    oferi opțiunea de a reconfirma comanda la prețul corect sau de a o anula.
    Dacă nu vă putem contacta, din motive neimputabile nouă, comanda se va
    considera anulată și dacă ați plătit deja produsul/produsele noastre, vă
    vom rambursa întreaga sumă plătită.
</p>
<p dir="ltr">
    Nu avem nicio obligație de a vinde vreun produs la un preț inferior
    incorect, chiar și in situația ăn care ați primit din partea noastră
    confirmarea comenzii, dacă eroarea de preț este evidentă, inconfundabilă și
    ar fi putut fi identificată în mod rezonabil de dumneavoastră ca fiind o
    eroare.
</p>
<p dir="ltr">
    Prețurile pot fi revizuite în orice moment, însă (cu excepțiile menționate
    mai sus) nicio schimbare potențială nu va afecta vreo comandă a
    dumneavoastră pentru care, anterior modificării prețurilor, a fost trimisă
    deja o Confirmare a comenzii.
</p>
<p dir="ltr">
    Comanda este finalizată în momentul în care primiți un e-mail pe adresa
    comunicată sau un mesaj de pe nr. dumneavostră de telefon introdus. Pentru
    a finaliza în siguranță comanda citiți cu atenție termenii și condițiile de
    vânzare. E-mailul de confirmare sau sms-ul, includ datele și informațiile
    specifice programului pentru care s-a făcut comanda.
</p>
<p dir="ltr">
    Aceste prevederi se aplică în mod corespunzător și serviciilor oferite
    contra cost.
</p>
<p dir="ltr">
    V.3. Plasarea comenzii
</p>
<p dir="ltr">
    Pentru plasarea unei comenzi, se selectează unul dintre produsele prezente
pe site-ul    <a href="https://antalszabyfitness.com/">https://antalszabyfitness.com</a>
    , din secțiunea Programe de antrenament, apăsând pe butonul ’’Cumpara’’.
    Cumpărătorul va introduce datele personale necesare pentru facturare,
    urmând să fie redicretionat la platforma procesatorului de plăți online
    furnizat de compania Euplătesc.ro, unde se vor cere datele cardului bancar
    pentru finalizarea plății.
</p>
<p dir="ltr">
    V.4 Preluarea și procesarea comenzilor.
</p>
<p dir="ltr">
    Preluarea și procesarea comenzilor transmise prin intermediul site-ului se
    face în termen de 48 de ore. Cumpărătorul va primi un mesaj sau un e-mail
    de confirmare a comenzii din partea sistemului.
</p>
<p dir="ltr">
    Odată cu efectuarea comenzii, cumpărătorul va achita taxele aferente prin
    intermediului procesatorului de plăți online. Astfel, fiecare comandă de pe
    site va fi efectuată cu cardul prin introducerea datelor de pe acesta în
    sistemul de plăți pus la dispoziție de procesator.
</p>
<p dir="ltr">
    După finalizarea înregistrării, persoana care a achiziționat programul
    online prin intermediul sistemului de plată electronic este proprietarul
    programului și beneficiază de opțiunile care sunt puse la dispoziție prin
    intermediul acestuia.
</p>
<p dir="ltr">
    V.5. Încheierea contractului și transferul drepturilor de proprietate
</p>
<p dir="ltr">
    Momentul încheierii contractului este momentul în care Vânzătorul a
    confirmat plata în mod electronic. Astfel, după recepționarea sumei ce
    reprezintă cuantumul programului, comanda va avea semnificația unui
    contract electronic. Informațiile cuprinse pe platforma noastră nu
    constituie o ofertă de vânzare, ci o invitație la ofertă. Nu va exista
    niciun contract între dumneavoastră și noi în legatură cu niciun produs
    până în momentul în care comanda dumneavoastră este acceptată în mod expres
    de către noi. Daca nu vă acceptăm oferta, iar din contul dumneavoastra au
    fost retrase deja fonduri, acestea va vor fi rambursate integral.
</p>
<p dir="ltr">
    Dreptul de proprietate asupra produselor vi se va transfera dumneavoastră
    numai după ce primim plata integrală a tuturor sumelor datorate pentru
    produse, precum și al oricăror costuri aferente necesare realizării
    operațiunii dacă aceasta are loc la o dată ulterioară. Dreptul legal de
    proprietate asupra produselor ne va reveni imediat nouă dacă vă rambursăm
    orice astfel de plată.
</p>
<p dir="ltr">
    Contractele electronice sunt guvernate de Legea nr. 365/2002 privind
    serviciile de comerț electronic. Dispozițiile prevăzute în prezenta lege
    reglementează condițiilor de furnizare a serviciilor societăților
    informaționale și prevăd o serie de obligații cu privire la serviciile de
    comerț electronic pe care vânzătorul trebuie să le respecte.
</p>
<p dir="ltr">
    Datele de facturare se vor preciza în procesul de înregistrare.
</p>
<p dir="ltr">
    După primirea e-mailului sau sms-ului de confirmare a plății, contractul
    electronic se încheie între părțile contractante prin acceptarea acesteia.
</p>
<p dir="ltr">
    În ceea ce privește respectarea drepturilor consumatorilor, prezentul
    contract este guvernat de ordonanța de urgență nr. 34/2014 privind
    aspectele de natura comercială între consumatori și întreprinderile
    furnizatoare de servicii. De asemenea, respectă reglementările incluse în
    Directiva 2011/83/UE a Parlamentului European și a Consiliului privind
    drepturile consumatorilor.
</p>
<p dir="ltr">
    Conform O.U.G. nr. 34/2014 consumatorul ce încheie un contract la distanță
    are dreptul de a se retrage în termen de 14 zile fără a justifica un motiv.
    Astfel, în cazul în care persoana care achiziționează unul dintre
    programele noastre de antrenament și dorește retragerea din contractul
    încheiat trebuie să trimită un e-mail la adresa
    antalszaby@antalszabyfitness.com în care să precizeze datele de
    identificare ale persoanei care a făcut comanda, numărul de comandă, data
    și semnătura. Cu privire la dreptul de retragere a consumatorilor,
    condițiilor, termenelor sunt aplicabile preverile din art. 9 din OUG.
    34/2014.
</p>
<p dir="ltr">
    În alte cazuri, după trecerea termenului 14 zile, cumpărătorul nu are
    dreptul la rambursarea sumei indiferent de orice situație intervine.
    Programul nu poate fi modificat, anulat, sau retras.
</p>
<p dir="ltr">
    V.6. Politica privind rambursarea banilor
</p>
<p dir="ltr">
    Platforma nu este obligată să răspundă solicitărilor de returnare a
    costului făcute prin intermediul acesteia, în cazul întârzierilor sau
    anulării, întrucât acest tip de activitate nu intră în aria de desfășurare
    a activității platformei.
</p>
<p dir="ltr">
    VI. Limitarea răspunderii
</p>
<p dir="ltr">
    Nu oferim nicio garanţie că funcţionarea website-ului va fi fără
    întrerupere sau fără erori sau că website-ul sau serverul care îl face
    disponibil sunt lipsite de viruşi sau orice alte elemente ce pot fi
    dăunătoare sau distructive.
</p>
<p dir="ltr">
    De asemenea, nu răspundem pentru prejudiciile cauzate pentru link-urile
    terților în conformitate cu cele mai sus precizate (III. Linkuri către
    website-urile terţilor).
</p>
<p dir="ltr">
    VII. Drepturi de proprietate intelectuală protejate
</p>
<p dir="ltr">
    Interfețele vizuale, grafica, designul, informațiile, codul computerului
    (inclusiv codul sursă și codul obiectului), produsele, serviciile și toate
    celelalte elemente ale site-ului și platformei sunt protejate de drepturi
    de autor, legi comerciale, brevete și mărci comerciale, convenții
    internaționale și toate celelalte proprietăți intelectuale relevante și
    drepturi de proprietate și legi aplicabile. Toate materialele puse la
    dispoziție către abonați sunt protejate de drepturi de proprietate
    intelectuală pe care declarăm că le respectăm și că acestea nu încalcă
    niciun drept de autor ( copyright) sau orice alt drept conex. Dumneavoastră
    sunteți de acord să nu copiați ,să efectuați public sau să afișați, să
    transmiteți, să publicați, să modificați, să adaptați, să creați lucrări
    derivate sau să faceți altfel utilizarea neautorizată a lucrărilor ce sunt
    protejate de drepturi de proprietate intelectuală. Aceste încălcări, precum
    și comercializarea sau încercarea de comercializare a conținutului vor fi
    apreciate ca fiind încălcări ale dreptului de proprietate intelectuală.
</p>
<p dir="ltr">
    VIII. Legi aplicabile şi dispute
</p>
<p dir="ltr">
    Termenii și Condițiile de față sunt guvernate de şi formulate în acord cu
    legea din România ale cărei instanțe de judecată au competență exclusivă
    asupra tuturor disputelor ce rezultă din acești Termeni și Condiții , iar
    dumneavoastră sunteţi de acord că locul de punere în aplicare al acestor
    Termeni și Condiții este România.
</p>
<p dir="ltr">
    IX. Înţelegerea completă
</p>
<p dir="ltr">
    Termenii și Condițiile împreună cu alte documente la care se face referire
    expres în Termeni și Condiții includ întreaga înţelegere dintre dvs. și
    Antal Szaby Fitness în legătură cu utilizarea website-ului şi înlocuiesc
    orice înţelegeri, aranjamente, angajamente sau propuneri anterioare, scrise
    sau orale. Orice explicaţie orală sau informare orală dată de vreuna dintre
    cele două părţi nu poate altera interpretarea Termenilor şi Condiţiilor.
    Prin acceptarea acestor Termenil şi Condiţii, nu v-aţi bazat pe o altă
    reprezentare decât cea stipulată în aceşti Termeni şi Condiƫii şi sunteţi
    de acord că nu veţi avea nicio cale de atac cu privire la orice falsă
    reprezentare ce nu a fost exprimată expres în aceşti Termeni şi Condiƫii.
</p>
<p dir="ltr">
    X. Actualizări ale Termenilor și Condițiilor.
</p>
<p dir="ltr">
    Este posibil să fie necesar să revizuim în viitor acești Termeni și
    Condiții. În cazul în care modificăm Termenii și Condițiile, vom publica
    Termenii și Condițiile revizuite pe website și vom semnala versiunea
    actualizată modificând data „Ultimei actualizări” din partea de sus a
    Termenilor și Condițiilor. După data la care Termenii și Condițiile
    actualizate intră în vigoare, accesarea website-ului nostru va reprezenta
    acceptarea de către dvs. a respectivilor Termeni și Condiții actualizate.
</p>
<p dir="ltr">
    XI. Date cu caracter personal sunt prelucrate.
</p>
<p dir="ltr">
    În conformitate cu dispozițiile Legii nr. 190/2018 pentru punerea în
    aplicare a Regulamentului 679/2016 privind prelucrarea datelor cu caracter
    personal, Antal Szaby Fitness prin intermediul website-ului va prelucra
    următoarele categorii de date cu caracter personal:
</p>
<p dir="ltr">
    1. Numele și prenumele;
</p>
<p dir="ltr">
    2. Adresa de e-mail;
</p>
<p dir="ltr">
    3. Adresa de facturare și/ sau de domiciliu;
</p>
<p dir="ltr">
    4. IP-ul calculatorului și/ sau identificatorul unic al telefonului mobil;
</p>
<p dir="ltr">
    5. Date de tipul cookies de pe aplicație și site;
</p>
<p dir="ltr">
    6. Tipul cardului bancar, ultimele patru cifre ale acestuia precum și data
    expirării, în cazul în care abonatul va selecta opțiunea referitoare la
    salvarea datelor cardului.
</p>
<p dir="ltr">
    Colectarea datelor cu caracter personal se va face în scopul executării
    dispozițiilor contractuale, în speță accesarea conținutului oferit de
    platforma noastră precum și achiziționarea oricăror programe sau servicii,
    ori al atltor activități aferente disponibile pe platformă și care implică
    operarea, colectarea și procesarea unor asemenea date. Datele enumerate mai
    sus vor fi stocate și prelucrate pe o perioadă necesară îndeplinirii
    drepturilor și obligațiilor părților, însă nu mai mult de 9 ani, începând
    cu executarea ultimei obligații contractuale, cu excepția cazului în care
    alte dispoziții legale nu stipulează o altă perioadă.
</p>
<p dir="ltr">
    Temeiul legal pentru aceste procesări este cel prevăzut de art. 6 alin. 1
    lit. b) și f) din Regulament: b) executarea unui contract în care persoana
    vizată este parte sau pentru a face demersuri, la cererea persoanei vizate,
    înainte de încheierea unui contract; f) în scopul intereselor legitime
    urmărite de Operator. Facem tot posibilul ca toate aceste date cu caracter
    personal să rămână confidențiale și să nu fie transmise către terți, cu
    excepția furnizorilor de platforme de plăți și a furnizorilor de servicii
    de publicitate online, dar numai cu privire la acele date care sunt
    necesare pentru a se efectua plata abonamentului precum și către furnizorii
    de servicii informatice: Stripe, Google Ads, Facebook Ads. Garantăm că
    prelucrarea datelor cu caracter personal este făcută strict în scopul
    îndeplinirii obiectului contractual și/ sau a interesului legitim, că nu va
    folosi aceste date în alte scopuri și că va asigura securitatea și
    păstrarea corespunzătoare a documentelor ce conțin aceste categorii de
    date. Prin accesarea site-ului si abonare vă exprimați acordul ca datele
    dumneavoastră cu caracter personal să fie prelucrate. Operatorul va lua
    măsurile necesare pentru a ne asigura că datele dumneavoastră sunt
    prelucrate, stocate și securizate în conformitate cu reglementările în
    vigoare. De asemenea, transmiterea datelor către terții prin care platofrma
    noastră își exercită activitatea este necesară îndeplinirii scopului
    antemenționat. Terților le este limitată capacitatea de a folosi și
    prelucra aceste date legal și contractual în alte scopuri decât cele
    prevăzute în prezentul document și ne vom asigura că aceștia vor fi supuși
    obligațiilor de confidențialitate și securitate. Exceptând punerea la
    dispoziție a datelor către terții indicați mai sus, nu se vor divulga
    datele dumneavoastră cu caracter personal către alte persoane, în lipsa
    unui temei legal, fără a obține prealabil consimțământul dumneavoastră.
</p>
<p dir="ltr">
    Datele cu caracter personal prelucrate de Operator vor fi stocate astfel:
</p>
<p dir="ltr">
    (a) pe dispozitivele informatice aparținând personalului Operatorului, în
    format editabil sau scanat;
</p>
<p dir="ltr">
    (b) pe pe serverele societatii.
</p>
<p dir="ltr">
    Vă informăm că, în conformitate cu prevederile Regulamentului, aveți
    următoarele drepturi în calitate de persoană vizată:
</p>
<p dir="ltr">
    1. Dreptul de acces: acesta vă asigură posibilitatea de a solicita
    informații legate de categoriile de date personale pe care le prelucrăm,
    scopul prelucrării, terții care au acces la aceste date, precum și orice
    altă informație relevantă pe care doriți să o aflați;
</p>
<p dir="ltr">
    2. Dreptul la rectificarea datelor: în cazul în care datele comunicate nu
    mai sunt de actualitate sau în cazul unei erori materiale, puteți solicita
    ca acesta sa fie rectificate;
</p>
<p dir="ltr">
    3. Dreptul la ștergerea datelor: acest drept este limitat de necesitatea
    noastră de (a) a le deține și prelucra, în cazul unei obligații sau a unui
    drept legal pe care o/îl avem, (b) în scopul de arhivare în interes public,
    (c) în scop statistic sau (d) orice alt interes sau scop care este necesar
    în exercitarea activității noastre sau care ar rezulta în urma raporturilor
    contractuale dintre părți;
</p>
<p dir="ltr">
    4. Dreptul la restricționarea prelucrării: în cazul în care
</p>
<p dir="ltr">
    (a) prelucrarea ar fi nelegală, până la verificarea și eventual constatarea
    nelegalității prelucrării;
</p>
<p dir="ltr">
    (b) apreciați că datele dumneavoastră nu sunt corecte, pe perioada
    verificării corectitudinii acestora;
</p>
<p dir="ltr">
    (c) alte motive bine întemeiate care să justifice o astfel de
    restricționare;
</p>
<p dir="ltr">
    5. Dreptul de a vă opune prelucrării: vi se recunoaște acest drept în cazul
    în care vă opuneți prelucrării justificate de interesul legitim al
    operatorului, cu excepția situației în care
</p>
<p dir="ltr">
    (a) motivele prelucrării sunt legitime și prevalează asupra intereselor,
    drepturilor și libertăților dumneavoastră sau
</p>
<p dir="ltr">
    (b) dacă prin prelucrarea datelor este exercitarea sau apărarea unui drept
    în cadrul instanțelor de judecată. În cazul în care vă opuneți prelucrării
    datelor a căror consimțământ expres a fost acordat prealabil de către
    dumneavoastră, aveți posibilitatea retragerii consimțământului.
</p>
<p dir="ltr">
    6. Dreptul la portabilitatea datelor: Regulamentul vă permite dreptul de a
    primit datele dumneavoastră personale pe care ni le-ați furnizat precum și
    dreptul de a solicita comunicarea datelor către o altă instituție, în
    măsura în care o să avem posibilitatea logistică și tehnică de a proceda la
    o astfel de comunicare.
</p>
<p dir="ltr">
    7. Dreptul de a depune o reclamație la autoritatea de supraveghere a
    protecției datelor personale, datele de indentificare ale instituției sunt:
</p>
<p dir="ltr">
    Denumire: Autoritatea Națională de Supraveghere a Prelucrării Datelor cu
    Caracter Personal
</p>
<p dir="ltr">
    Adresă: B-dul G-ral. Gheorghe Magheru 28-30, Sector 1, cod poștal 010336,
    București, România
</p>
<p dir="ltr">
    Telefon: +40.318.059.211 +40.318.059.212
</p>
<p dir="ltr">
    Fax: +40.318.059.602
</p>
<p dir="ltr">
    Email: anspdcp@dataprotection.ro
</p>
<p dir="ltr">
    XII. Reclamații
</p>
<p dir="ltr">
    Orice reclamație cu privire la modul de desfășurare a activității site-ului
    nostru se va face în scris prin transmiterea unui e-mail la adresa de
    contact antalszaby@antalszabyfitness.com.
</p>
<p dir="ltr">
    Vânzătorul are obligația de a procesa reclamația în cel mai scurt termen.
</p>
<p dir="ltr">
    Termenii și condițiile prezente sunt guvernate de legislația din România.
    În cazul unui litigiu, părțile au obligația de a încerca soluționarea
    acestuia pe cale amiabilă. Dacă acest lucru nu va fi posibil, partea care
    se consideră prejudiciată se va putea adresa instanțelor competente.
</p>
<p dir="ltr">
    XIII. Contact
</p>
<p dir="ltr">
    P.F.A. Antal Szabolcs-Sandor
</p>
<br/>
<p dir="ltr">
    Loc. Reghin, Str Stadionului nr.1
</p>
<br/>
<p dir="ltr">
    C.U.I. 43045626
</p>
<br/>
<p dir="ltr">
    aszabyfitness@gmail.com
</p>
<br/>
<GoToTop/>
</div>
    );
};