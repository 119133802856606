import React, {Component} from "react";
import Fade from "react-reveal";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PermPhoneMsgIcon from '@mui/icons-material/PermPhoneMsg';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import GavelIcon from '@mui/icons-material/Gavel';
import LocalPoliceIcon from '@mui/icons-material/LocalPolice';
import CookieIcon from '@mui/icons-material/Cookie';
import {Link} from "react-router-dom";
import styled from "styled-components";

const MyFooter = styled.footer`
    background-color: black;
    color: white;
    padding: 100px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    @media (max-width: 800px) {
        padding: 20px;
    }
`;
const FooterContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 800px;
    @media (max-width: 800px) {
        width: 100%;
    }
`
const ContactContainer = styled.div`
    display: flex;
    justify-content: space-around;
    padding-top: 25px;
    padding-bottom: 25px;
    @media (max-width: 800px) {
        flex-direction: column;
    }
`;
const ContactDescriptionContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    padding-left: 10%;
    @media (max-width: 800px) {
        padding-top: 40px;
    }
`
const ContactDescription = styled.div`
    display: flex;
    padding-bottom: 10px;
    padding-right: 20px;
    & .svg-icon {
        color: rgba(214, 10, 10, 0.651);
        cursor: pointer;
    }
    & p {
        margin-right: 10px;
        margin-left: 10px;
    }
`
const ViewMore = styled(Link)`
    color: white;
    text-decoration: none;
`;
const SocialURLsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`;
const SocialURL = styled.a`
    margin-right: 10px;
    margin-left: 10px;
    & .svg-icon {
        color: rgba(214, 10, 10, 0.651);
        cursor: pointer;
    }
`;
const Copyright = styled.p`
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
`;

class Footer extends Component {
    render() {
        return (
            <MyFooter>
                <Fade right>
                    <FooterContainer>
                        <ContactContainer>
                            <ContactDescriptionContainer>
                                <ContactDescription>
                                    <MailOutlineIcon className={"svg-icon"}/>
                                    <p>aszabyfitness@gmail.com</p>
                                </ContactDescription>
                                <ContactDescription>
                                    <PermPhoneMsgIcon className={"svg-icon"}/> <p>+40 753 456 933</p>
                                </ContactDescription>
                                <ContactDescription>
                                    <LocationOnIcon className={"svg-icon"}/> <p>ROMÂNIA, CLUJ-NAPOCA</p>
                                </ContactDescription>
                            </ContactDescriptionContainer>
                            <ContactDescriptionContainer>
                                <ContactDescription>
                                    <GavelIcon className={"svg-icon"}/>
                                    <ViewMore to="/termenii">
                                        <p>Termenii si conditii</p>
                                    </ViewMore>
                                </ContactDescription>
                                <ContactDescription>
                                    <CookieIcon className={"svg-icon"}/>
                                    <ViewMore to="/cookies">
                                        <p>Politica de cookie-uri</p>
                                    </ViewMore>
                                </ContactDescription>
                                <ContactDescription>
                                    <LocalPoliceIcon className={"svg-icon"}/>
                                    <a href="https://anpc.ro" target={"_blank"} rel="noreferrer"
                                       style={{color: "white", textDecoration: "none"}}>
                                        <p>ANPC</p>
                                    </a>
                                </ContactDescription>
                            </ContactDescriptionContainer>
                        </ContactContainer>
                        <div>
                            <Copyright>
                                © Antal Szaby
                            </Copyright>
                        </div>
                        <SocialURLsContainer>
                            <SocialURL href="https://www.facebook.com/aszabypersonaltraining" target={"_blank"}
                                       rel="noreferrer">
                                <FacebookIcon className={"svg-icon"}/>
                            </SocialURL>
                            <SocialURL href="https://www.instagram.com/aszaby.fitness/" target={"_blank"}
                                       rel="noreferrer">
                                <InstagramIcon className={"svg-icon"}/>
                            </SocialURL>
                            <SocialURL href="https://youtube.com/channel/UChwj02LtID1jmbqXkdEwBUg" target={"_blank"}
                                       rel="noreferrer">
                                <YouTubeIcon className={"svg-icon"}/>
                            </SocialURL>
                        </SocialURLsContainer>
                    </FooterContainer>

                </Fade>
            </MyFooter>
        );
    }
}

export default Footer;
