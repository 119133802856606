import React from 'react';
import './Jumbotron.css';
import backgroundVideo from './SZABY_FITNESS_HD.mov';
import backgroundVideoMobile from './Szaby_Fitness_Patrat.mov';
import styled from "styled-components";
import {isMobile} from 'react-device-detect';

const Section = styled.section`
    height: 100vh;
`;

const Video = styled.video`
    object-fit: cover;
    width: 100% !important;
    height: 100vh !important;
    @media (max-width: 800px) {
        max-height: 100%;
        max-width: 100%;
    }
`;

export default function Jumbotron() {
    return (
        <Section id="jumbotron">
            <Video onContextMenu={e => { e.preventDefault();}} onClick={e => { e.preventDefault();}}
                   id="jumbotronVideo" preload="metadata" autoPlay={true} playsInline loop={true} controls={false} muted >
                <source src={isMobile ? backgroundVideoMobile + "#t=0.1" : backgroundVideo + "#t=0.1"} type="video/mp4"/>
            </Video>
        </Section>
    );
};
