import styled from "styled-components";
import {ReactComponent as PT} from './pt.svg';
import {ReactComponent as OnlineCoaching} from './online-coaching.svg';
import {ReactComponent as Nutrition} from './nutrition.svg';
import InfoIcon from '@mui/icons-material/Info';
import {Link} from "react-router-dom";


const PTIcon = () => <PT/>
const OnlineCoachingIcon = () => <OnlineCoaching/>
const NutritionIcon = () => <Nutrition/>

const MoreButton = styled(Link)`
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    margin: 0;
    margin-top: 20px;
    padding: 8px;
    width: 80%;
    max-width: 260px;
    color: white;
    text-align: center;
    text-decoration: none;
    font-size: 13px;
    letter-spacing: 1px;
    border: 2px solid rgba(214, 10, 10, 0.651);;
    cursor: pointer;
    align-items: center;    
    & svg {
        margin-right: 10px;
        padding: 2px;
    }
    @media (max-width: 800px) {
        font-size: 12px;
        width: 100%;
    }
`;

const Section = styled.section`
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    padding-top: 70px;
    padding-bottom: 70px;
    background: black;
    color: white;
    @media (max-width: 800px) {
        padding-top: 40px;
        padding-bottom: 40px;
        padding-left: 20px;
        padding-right: 20px;
    }
`;

const TitleContainer = styled.div`
    display: flex;
    justify-content: center;
    text-transform: uppercase;
`

const ServicesContainer = styled.div.attrs(props => ({
    id: "services-container"
}))`
    display: flex;
    flex-direction: row;
    justify-content: center;
    @media (max-width: 800px) {
        flex-direction: column;
        height: 100%;
    }
`

const ServiceContainer = styled.div.attrs(props => ({
    className: "my-service-container"
}))`
    width: 350px;
    display: flex;
    flex-direction: column;
    text-align: center;
    & svg {
        width: 50px;
        color: red;
    }    
    & h2 {
        padding-top: 20px;
        padding-bottom: 20px;
        text-transform: uppercase;
        font-size: 20px;
    }
    & p {
        line-height: 25px;
    }
    & ul {
        padding-left: 35px;
        padding-top: 15px;
        font-size: 14px;
        text-align: left;
    }
    margin: 30px;
    border: 1px solid rgba(214, 10, 10, 0.651);
    padding: 15px;
    @media (max-width: 800px) {
        width: 100%;
        margin: 0;
        margin-top: 10px;
        margin-bottom: 10px;
    }
`;
const PulsatingServiceContainer = styled.div.attrs(props => ({
    className: "pulsating-my-service-container"
}))`
    width: 350px;
    display: flex;
    flex-direction: column;
    text-align: center;
    & svg {
        width: 50px;
        color: red;
    }    
    & h2 {
        padding-top: 20px;
        padding-bottom: 20px;
        text-transform: uppercase;
        font-size: 20px;
    }
    & p {
        line-height: 25px;
    }
    & ul {
        padding-left: 35px;
        padding-top: 15px;
        font-size: 14px;
        text-align: left;
    }
    margin: 30px;
    border: 1px solid rgba(214, 10, 10, 0.651);
    animation: pulse 1s infinite;
    padding: 15px;
    @media (max-width: 800px) {
        width: 100%;
        margin: 0;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    @keyframes pulse{
        from{
          box-shadow: 0 0 0 0 rgba(214, 10, 10, 0.85);
        }
  
        to{
          box-shadow: 0 0 0 30px rgba(214, 10, 10, 0);
        }
    }
`;
const IconContainer = styled.div`
    display: flex;
    justify-content: center;
    & svg {
        color: rgba(214, 10, 10, 0.651);;
        fill: rgba(214, 10, 10, 0.651);;
    }
`

const Service = ({icon, title, children}) => {
    return (
        <ServiceContainer>
            <IconContainer>
                {icon}
            </IconContainer>
            <h2>{title}</h2>
            {children}
        </ServiceContainer>
    );
};
const PulseService = ({icon, title, children}) => {
    return (
        <PulsatingServiceContainer>
            <IconContainer>
                {icon}
            </IconContainer>
            <h2>{title}</h2>
            {children}
        </PulsatingServiceContainer>
    );
};

const WhatsapLink = styled.a` 
   color: white;
   text-decoration: none;
   align-items: center;
   display: flex;
   font-size: 30px;
   @media(max-width: 800px) {
       font-size: 20px;
   }
`

export default function Services() {
    return (
        <Section id="services">
            <TitleContainer>
                <h2>Serviciile mele</h2>
            </TitleContainer>
            <ServicesContainer>
                <Service icon={<PTIcon/>} title="Personal Training">
                        <p>
                        Serviciul de Personal Training este investiția perfectã în sănătatea ta fizicã si mentalã. Vei lucra direct cu mine în sala de fortã, urmând un plan alimentar & antrenament personalizat pentru a îti atinge obiectivele  într-un timp record.  Indiferent dacă doresti să slăbesti, să adaugi masã muscularã, programul de antrenament personal te va duce la atingerea obiectivelor.
                        </p>
                </Service>
                <PulseService icon={<OnlineCoachingIcon/>} title="Online Coaching" >
                        <p>
                        Optează pentru una dintre cele doua variante:<br/>
                        Pregatire competițională sau stil de viață.<br/>
                        Vei beneficia în functie de obiectivul tãu de un plan alimentar, medicație ( dacă este cazul), listă de suplimente & program de antrenament personalizat, cu continut video.
                        Adaptările se fac periodic în functie de evolutie si necesitate, iar  consultanta/check in-urile au loc via WhastApp periodic ( frecventa în funcție de situație 3-7 zile ) prin intermediul pozelor & cântar, si feedback în scris.
                         </p>
                </PulseService>
                <Service icon={<NutritionIcon/>} title="Nutrition">

                        <p>
                         Schimbarea stilului de viață? Dorința de a intra în formă? Probleme de sănătate ce îți îngreunează viață de zi cu zi ? 
                         Un program nutrițional bine stabilit este principalul factor de luat în considerare.
                         Consultanță nutriționala și un plan alimentar personalizat pentru nevoile fiecăruia:
                         </p>
                         <ul> 
                        <li>   
                         Schimbări ale compoziției corporale (tonifiere, acumulare de masă musculară, menținere etc )
                         </li>
                         <li>
                         Detox & probleme de sănătate 
                         </li>
                         <li>
                         Dietă vegană, ketogenică
                         </li>
                         </ul>
                </Service>
            </ServicesContainer>
            <div style={{display: "flex", justifyContent:"center"}}>
                    <MoreButton to={"/onlineCoaching"}>
                        <InfoIcon/>
                        <p>VREAU MAI MULTE DETALII</p>
                    </MoreButton>
                </div>
        </Section>
    );
};