import styled from "styled-components";
import a1 from "./images/a1.jpg";
import a2 from "./images/a2.jpg";
import e1 from "./images/e1.jpg";
import e2 from "./images/e2.jpg";
import i1 from "./images/i1.jpg";
import i2 from "./images/i2.jpg";
import io1 from "./images/io1.jpg";
import io2 from "./images/io2.jpg";
import m1 from "./images/m1.jpg";
import m2 from "./images/m2.jpg";
import r1 from "./images/r1.jpg";
import r2 from "./images/r2.jpg";
import sz1 from "./images/sz1.jpg";
import sz2 from "./images/sz2.jpg";
import React from "react";
import l1 from "./images/l1.jpg";
import l2 from "./images/l2.jpg";
import v1 from "./images/v1.jpg";
import v2 from "./images/v2.jpg";
import o1 from "./images/o1.jpg";
import o2 from "./images/o2.jpg";
import an1 from "./images/an1.jpg";
import an2 from "./images/an2.jpg";
import rz1 from "./images/rz1.jpg";
import rz2 from "./images/rz2.jpg";
import k1 from "./images/k1.jpg";
import k2 from "./images/k2.jpg";
import mihai1 from "./images/mihai1.jpg";
import mihai2 from "./images/mihai2.jpg";
import c1 from "./images/c1.jpg";
import c2 from "./images/c2.jpg";
import lr1 from "./images/lr1.jpg";
import lr2 from "./images/lr2.jpg";
import {Review, ReviewContainer} from "./Results";
import GoToTop from "../common/utilities";

const Container = styled.div`
    display: flex;
    flex: 1 1 0;
    flex-direction: column;
    background: #454545;
    padding-left: 40px;
    padding-right: 40px;
    @media (min-width: 800px) {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
        padding-top: 50px;
        padding-bottom: 50px;
        & ${ReviewContainer} {
            padding-top: 40px;
            width: 45%;
        }
    }
`;

export default function DetailedResults() {
    return (
        <Container>
                                <Review icon1={k1} icon2={k2} name="Kinga">
                    Szabi pentru mine este mai mult decât un antrenor- este un mentor și un sprijin emoțional enorm, de multe ori și psiholog. Ceea ce îl face cu adevărat special este abilitatea lui de a explica concepte complexe într-un mod ușor de înțeles.

Pasiunea lui contagioasă pentru fitness m-a inspirat să încerc pregătirea pentru un concurs de fitness, cea ce m-a ajutat să dezvolt o forță mentală remarcabilă. 

El a fost un sprijin urias, m-a învățat cum să îmi planific mesele, cum să îmi calculez caloriile pentru diferite situatii si contexte, și cum să abordez antrenamentele și intensitatea / greutățile cu încredere.

Pregătirea pentru un concurs de fitness  este cu două tăișuri din punct de vedere emoțional. Pe de o parte, este foarte intensă. Te întărește mental și îți dai seama că poți sã îți depăsesti limitele pe care credeai că le ai. Apoi apar probleme legate de imaginea corpului tinzând spre perfecțiune luni întregi. În acest proces avem nevoie de un ochi exterior care ne poate readuce cu picioarele pe pământ și să ne facă să ne vedem imaginea mai clar. Un antrenor care vede lucid si real lucrurile și știe când și cum să acționeze. Un antrenor care, totodată ne poate impulsiona să lucrăm și mai tare când ne este greu. Și nu în ultimul rând un antrenor care înțelege corpul uman și are mereu "asul în mânecã" pentru a scoate cea mai bună formă din tine. El este Szaby !    
                    </Review>
                    <Review icon1={mihai1} icon2={mihai2} name="Mihai">
                    Bună ,sunt Mihai și am colaborat cu Szaby pentru un an de zile.
Un an in care mi s-a schimbat viața total,el având o contribuție  decisivă.
Noi am colaborat pentru o pregătire competițională din 01 oct 2021 până în noiembrie 2022 .
Totul a decurs conform planului ,am avut rezultate extraordinare și o transformare cum nu credeam.Prin el mi-am descoperit potențialul genetic și am descoperit culturismul.Lui i se datorează tot ce a urmat dupa.
Țin minte si acum ,că m-a văzut in vestiar și m-a intrebat de ce nu duc asta la următorul nivel pentru că potențial este mult,nu stiam eu atunci ce v a urma😅
A fost lângă mine trup și suflet pe toată durata colaborării și m-a impins la propriu spre obiectivul meu.
Vi-l recomand cu mare drag pentru pregatiri de concurs ,antrenamente personale si nu numai,rezultatele lui vorbesc si dovedesc ca este capabil❤️</Review>
                    <Review icon1={c1} icon2={c2} name="Cristi">
                    Am fost retinut la inceput in privinta online coaching-ului, am auzit despre Antal Szaby - Personal Training & Nutrition de la un prieten care facea deja antrenamente cu el si era foarte multumit. Asa ca am zis sa incerc si eu, s-a dovedit a fi cel mai bun lucru pe care l-am facut pentru ca in doar 2 luni jumate am reusit sa am rezultate foarte foarte bune ca niciodata (desi m-am mai antrenat perioade lungi de timp dar parca bateam pasul pe loc).
El este implicat in procesul meu de transformare chiar mai mult decat sunt eu, ajusteaza periodic regimul alimentar si antrenamentele pentru a avea rezultate maxime, si chiar au aparut mai repede decat ma asteptam! 😍
 </Review>
            <Review icon1={a1} icon2={a2} name="Alpar">
                Szaby este omul care schimbă vieți prin profesionalism, răbdare și pasiune. Este un antrenor care știe să adapteze antrenamentul și planul alimentar în fucție de necesitățile tale. Am fost din totdeauna un om sportiv, dar până cănd nu l-am cunoscut pe Szaby nu știam cât de important este un plan alimentar potrivit pentru a ajunge rezultatele dorite. Datorită profesionalismului lui am reușit să îmi schimb stilul de viață în unul sănătos și să am rezultate vizibile și pe corp. Eu îi sunt foarte recunoscător și îl recomand cu încredere pentru toți cei care vor o schimbare în bine în viața lor.            </Review>
            <Review icon1={e1} icon2={e2} name="Edi">
            Alături de Szaby am venit intodeuna cu drag la sala și in zilele in care eram doborât psihic. Știam ca acolo ma aștepta intodeuna cu același chef nebun de a merge mai departe in îndeplinirea obiectivelor. Pe langa prima impresie de un antrenor profesionist am descoperit o pasiune pentru munca pe care o face,o determinare aparte și un prieten care ma ascultat și in momentele mai puțin bune. Datorită lui am inceput sa văd rezultate,am inceput sa prind încredere in mine și sa înțeleg ca sportul are un rol vital in viața mea, atât pe plan fizic cât și pe plan pshiic. Este un om care pune munca in sala de fitnes pe primul loc, un om determinat care îți transmite și tie acest lucru, un om care îți spune nu sari peste mese, un om care când spui ca nu mai poți sa tragi de aparat este mereu acolo lângă tine si te face sa poți. In încheiere il recomand in primul rând pentru ca munca lui se reflecta in rezultatele pe care le obții,este serios, perseverent, perfecționist si alături de el pe lângă sport te alegi cu un prieten.
             </Review>
            <Review icon1={i1} icon2={i2} name="Icza">
            Luni intregi am incercat din greu sa fac ceva pentru a-mi re-castiga increderea in mine..sa imi placa ceea ce vad in oglinda.
Una dintre cele mai bune decizii pe care le-am luat vreodata a fost cand l-am contactat pe Szabi. El este extrem de ajutator, este mai mult decat un PT, e atent cu tine si are grija ca in orice conditie tu sa te mentii in forma ta cea mai buna. 
Chiar daca este o colaborare online, s-a dovedit ca este una puternica, si foarte eficienta in acelasi timp. 
M-a ascultat si cand m-am simtit frustrata sau cand mi-a fost frica sa ii spun ca am obosit si ca nu mai am rabdare .. 
Mereu gaseste o solutie care o sa iti fie perfect pentru tine si cu care sa iti atingi scopurile 
Cand i-am scris, mi-a fost rusine, m-am simtit intimidata, chiar nesigura pe mine. Nu stiam la ce am sa ma astept.. INSA in fiecare zi Szabi s-a dovedit a fi o persoana care e gata sa te mentina motivat.  
           </Review>
            <Review icon1={io1} icon2={io2} name="Ionut">
            Am inceput colaborarea cu szaby la finalul verii și după 5 luni de munca grea am reușit să ajung la rezultatul pe care mi l am dorit de când eram mic.Dupa un an de făcut sală individual nu am reușit să ajung la corpul pe care îl visam dar în 5 luni alături de un antrenor care te motiveaza de fiecare dată când nu mai ai chef,te ajuta mereu să evoluezi și să dai tot la antrenament am reusit o schimbare majora! Îți schimbă alimentația de cate ori ai nevoie și o adaptează la ce alimente îți plac (te mai lasă să faci și cheaturi), antrenamente solicitante și diferite adaptate nevoilor tale și în plus suport și explicații ori de câte ori nu înțelegi ceva din planul alimentar și nu numai.work smarter not harder.
In cele din urma, ca si bonus este faptul ca am devenit buni prieteni.
            </Review>
            <Review icon1={m1} icon2={m2} name="Mira">
            Din 2019, am inceput colaborarea si-n afara de antrenament, am invatat disciplina, iar cand esti competitiv si intalnesti un om ambitios ca Szaby, intri-n hora si joci…te determina sa iti doresti sa fii o versiune mai buna a ta si asta m-a ambitionat mereu in colaborarea cu el, iar rezultatele au fost pe masura.
M-a antrenat inclusiv in sarcina si datorita lui, intoarcerea la antrenamente a fost mai usoara, la fel si sarcina.            </Review>
            <Review icon1={r1} icon2={r2} name="Robert">
                Am ales sa incep colaborarea cu Szaby intr-un moment de stagnare cand a inceput sa scada si motivatia de a merge la sala.A fost o decizie pe care nu regret nici pana in ziua de azi pentru ca am schimbat in bine multe aspecte din viata mea. Mananc mai sanatos, ma duc cu mai multa placere la sala pentru ca rezultatele ma motiveaza - toate datorita profesionalismul lui Szaby. Antrenamentele sunt faine, inveti multe pe parcurs, sunt motivat sa imi trec limitele si sa fac o extra repetare.Recomand, nu o sa regreti nici tu decizia.
            </Review>
            <Review icon1={sz1} icon2={sz2} name="Szabi">
            Inainte de a ma intalni cu Szabi,  am avut 2-3 incercari esuate la sala. Nici unul nu a durat mai mult de 2 saptamani. De la primul antrenament am stiut ca am ales corect. Mi-a spus foarte multe lucruri, este un invatator foarte bun, pana am invatat miscarile, la fiecare antrenament imi explica si vorbea ( si acum aud:" Pieptul scos!" ). Este un antrenor foarte bun, pune suflet in ceea ce face, si pentru mine a fost un prieten bun, care ma inspirat, care daca imi spunea sa fac inca 2 repetari, si daca ma rupeam in bucati de la oboseala eu faceam acele repetari, pentru ca am avut incredere in el - daca imi spunea ca eu is capabil sa o fac, o faceam fara intrebari. Am pasit cu un obiectiv in sala si Szabi m-a ajutat enorm de mult sa il ating. As reveni in orice moment la sala, daca cineva mi-ar spune ca Szabi va fi antrenorul meu. 
            </Review>
            <Review icon1={l1} icon2={l2} name="Laci">
            M-am apucat de sala cu scopul de a-mi dezvolta masa musculara si ca sa capat o oarecare rezistenta fizica.Intre timp am avut un accident la picior maleola mediala a fost sarita din ligamente . A fost nevoie de o perioada indelungata de recuperare ,dar Szaby a fost tot timpul atent la exercitii de recuperare . Pe Antal Szaby il cunosc de mult timp . Consider ca este un personal trainer foarte bun, care pe langa cunostintele multiple despre bodybuilding, nutritie ,etc . Este si un om deosebit. Cu toate ca in pauze mai si glumim, cand vine vorba de executarea exercitiilor este un om ce isi ia munca in serios, ce te indruma sa executi corect si eficient un antrenament, fapt ce duce la rezultate pe masura. Exercitiile ce le propune sunt diversificate pe masura evolutiei clientului. Cu alte cuvinte, il recomand cu cea mai mare caldura pe Antal Szaby ca personal trainer, deoarece nu o sa regretati de alegerea facuta.                    
            </Review>
            <Review icon1={v1} icon2={v2} name="Vali">
                        Colaborarea mea cu Szaby a început acum doi ani, când m-am hotărât să fac o schimbare în viața mea. Antrenamentele pe cont propriu ce le făceam până atunci au ajuns la o plafonare. Orice aș fi făcut nu mai aveam nici un progres și nici o îmbunătățire a fizicului meu. Astfel îmi scădea și motivația. Atunci m-am hotărât să îmi iau un antrenor personal. O alegere mai bună nici nu puteam să fac alegându-l pe Szaby. Din prima zi s-a dedicat într-un mod profesionist de antrenamentele personalizate scopului meu și de planul alimentar, iar rezultatele nu au întârziat să apară. Pot să zic că Szaby a reușit să mă motiveze și să-mi schimbe stilul de viață în bine. Acum mă simt foarte bine în corpul meu și toți prietenii îmi admiră rezultatele. Sunt foarte mulțumit de alegerea mea și îl recomand cu încredere!                    
            </Review>
            <Review icon1={o1} icon2={o2} name="Ovi">
            Am avut privilegiul de a lucra cu Szaby timp de mai bine de un an de zile si nu pot decat sa spun ca sunt incantat de rezultatele obtinute dar si de stilul nou de viata. 

El m-a ajutat sa imi adaptez rutina personala astfel incat obiectivele de fitness sa se potriveasca cu preferintele mele.

Il recomand pe Szaby pentru profesionalismul de care da dovada, de flexibilitatea lui si de experienta in domeniu pe care nu doar ca o preda altora, dar o si aplica personal.

            </Review>
            <Review icon1={an1} icon2={an2} name="Andrei">
            Am avut privilegiul de a lucra cu Szaby timp de mai bine de un an de zile si nu pot decat sa spun ca sunt incantat de rezultatele obtinute dar si de stilul nou de viata. 

El m-a ajutat sa imi adaptez rutina personala astfel incat obiectivele de fitness sa se potriveasca cu preferintele mele.

Il recomand pe Szaby pentru profesionalismul de care da dovada, de flexibilitatea lui si de experienta in domeniu pe care nu doar ca o preda altora, dar o si aplica personal.

            </Review>
            <Review icon1={rz1} icon2={rz2} name="Răzvan">
            Am ales să încep colaborarea cu Szaby într-un moment în care îmi pierdusem motivația de a merge la sală din cauza lipsei de rezultate. Încă de la începutul discuțiilor noastre s-a văzut ca e un om foarte profesionist, cu foarte multe cunoștințe și care pune suflet în ceea ce face. Antrenamentele cu el sunt intense, trebuie să împingi/tragi “ca și cum viața ți-ar depinde de asta”, dar și pline de informații. Planurile (alimentar/antrenament) sunt schimbate și adaptate nevoilor ori de câte ori e necesar. Recomand colaborarea cu Szaby, in primul rând pentru ca munca lui se reflectă în rezultatele pe care le are, pe lângă asta este un motivator înnăscut și ca bonus te alegi și cu un prieten. Go Champ! 💪🏽😁

            </Review>
            <Review icon1={lr1} icon2={lr2} name="Laurean">
            

            </Review>
            
        <GoToTop/>
        </Container>
    );
};