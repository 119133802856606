import "./App.css";
import { useState } from "react";
import styled from "styled-components";
import Logo from "./logo.jpg";
import GoToTop from "../common/utilities";
import {Link} from "react-router-dom";

const ArticleContainer = styled.div`
    
`;
const ImageContainer = styled.div`
   width: auto;
   height: auto;
    overflow: hidden; 
    display: inline-block;

    @media(max-width: 800px) {
    }
`;
const Image = styled.img`
    width: 100%;
    height: auto;
    transition: all .2s ease;
    vertical-align: middle;
    object-fit: cover;
`;
const ArticleContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding-left: 200px;
    padding-right: 200px;
    padding-top: 30px;
    padding-bottom: 100px;

    @media(max-width: 800px) {
        padding-left: 0;
        padding-right: 0;
        padding-top: 20px;
        padding-bottom: 20px;
    } 

`;
const ArticleDescriptionContainer = styled.div`
    & img {
        height: 550px;
        justify-content: center;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
        @media(max-width: 1200px) {
            height: 450px;
        }
        @media(max-width: 800px) {
            width: 300px;
            height: 300px;
        }
    }
    & div {
        justify-content: center;
        display: flex;
        max-width: 800px;
        flex-direction: column;
        padding-left: 50px;
        padding-right: 50px;
        line-height: 22px;

        & p{
            font-family: -apple-system, BlinkMacSystemFont, sans-serif;
        }

        & h2:not(:first-child) {
            margin-top: 20px;
        }
        & h2 {
            margin-bottom: 15px;
            font-style: bold;
            font-size: 30px;
            font-weight: 300;
            font-family: verdana;
            text-align: center;
            padding-bottom: 2px;
            line-height: 100%;
            color: #F84439;
        }
        @media(max-width: 800px) {
            font-size: 13px;
            padding-bottom: 25px;
        }
    }
    justify-content: center;
    display: flex;
    flex-direction: row;
    font: rockwell;

    @media(max-width: 800px) {
        align-items: center;
        flex-direction: column-reverse;
    }
`;


const ArticleDescriptionContent = ({title, children}) => {
    return (
        <ArticleDescriptionContainer>
            <div>
                <h2 style={{font: 'rockwell', justifyContent: 'center !important'}}>{title}</h2>
                {children}
            </div>
        </ArticleDescriptionContainer>
    );
};
const ViewMore = styled(Link)`
    color: black;
    paddingTop: 120px;
`;


function Challenges() {

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [age, setAge] = useState("");
  const [city, setCity] = useState("");
  const [message, setMessage] = useState("");

  const [agree, setAgree] = useState(false);

  const checkboxHandler = () => {
    // if agree === true, it will be set to false
    // if agree === false, it will be set to true
    setAgree(!agree);
    // Don't miss the exclamation mark
  }


  let handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let res = await fetch("https://antalszabyfitness.com/svc/challenges", {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          name: name,
          email: email,
          age: age,
          city: city,
        }),
      });
      if (res.status === 200) {
        setName("");
        setEmail("");
        setAge("");
        setCity("");
        setMessage("Înscriere cu success");
      } else {
        setMessage("Some error occured");
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <ArticleContainer>
    <ImageContainer>
         <Image src={Logo} />
    </ImageContainer>
    <ArticleContentContainer>
        <div className="App">

        <form onSubmit={handleSubmit}>
        <section style={{color:'red', maxWidth:'350px', fontSize: '20px', fontWeight: '300', fontFamily: 'verdana', paddingBottom:"17px"}}>Înscrie-te la extragerile săptămănale pentru o sesiune de antrenament 1v1 gratuită</section>

            <input
            type="text"
            key="name"
            value={name}
            placeholder="Nume"
            onChange={(e) => setName(e.target.value)}
            />
            <input
            type="text"
            key="email"
            value={email}
            placeholder="E-mail"
            onChange={(e) => setEmail(e.target.value)}
            />
            <input
            type="text"
            key="age"
            value={age}
            placeholder="Vărstă"
            onChange={(e) => setAge(e.target.value)}
            />
            <input
            type="text"
            key="city"
            value={city}
            placeholder="Oraș"
            onChange={(e) => setCity(e.target.value)}
            />

            <div style={{maxWidth:'350px'}}>
                <input style={{width:'15px', height:'15px',height:'auto', margin:'0px', verticalAlign:'middle'}} type="checkbox" id="agree" onChange={checkboxHandler} />
                <label style={{height:'10px'}} for="agree"> Sunt de acord cu <b>Politica de confidentialitate si prelucrarea datelor cu caracter personal
</b></label>
            </div>
            <button style={agree ? {backgroundColor:'red', paddingTop:'15px'} : {backroundColor:'grey', paddingTop:'15px'}} disabled={!agree} key="btnSubmit" type="submit">Înscriere</button>
        
            <div key="message" className="message">{message ? <p>{message}</p> : null}</div>
            <div style={{maxWidth:'350px', paddingTop:'20px'}}>
                <ViewMore to="/politconf">
                    <p>Politica de confidentialitate si prelucrarea datelor cu caracter personal</p>
                </ViewMore>
            </div>
        </form>
  

        </div>

    </ArticleContentContainer>
            <GoToTop/>
        </ArticleContainer>
  );
}

export default Challenges;