import React, {useEffect, useRef, useState} from "react";
import styled from 'styled-components'
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import logo from "./logo.png";
import logoBlack from "./logo-black.png";
import {useNavigate} from "react-router-dom";
import {debounce} from "../common/utilities";

const Container = styled.div.attrs(props => ({
    id: "header-container"
}))`
    position: fixed;
    top: 0;
    width: 100%;
    height: 80px;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    background: transparent;
    padding-left: 10px;
    padding-right: 10px;
    align-items: center;
    transition: top 0.6s;
`;

const Logo = styled.img.attrs(props => ({
    id: "logo",
    alt: "logo"
}))`
    padding-top: 15px;
    max-height: 90px;
    @media (max-width: 800px) {
    }
`

const Menu = styled.div.attrs(props => ({
    id: "menu-container"
}))`
    max-width: 100%;
    display: flex;
    align-items: center;
    > svg {
        color: gray;
        cursor: pointer;
        @media (min-width:800px) {
            display: none;
        }
    }
    @media (max-width: 800px) {
        position: relative;
    }
`;

const IconsContainer = styled.div.attrs(props => ({
    id: "icons-container"
}))`
    display: flex;
    align-items: center;
    @media (max-width:800px) {
        position: absolute;
        flex-direction: column;
        right: 15px;
        top: 50px;
        width: 120px;
        text-align: right;
        align-items: flex-start;
        > * {
            margin-top: 5px;
            margin-bottom: 5px;
        }
    }
`;

const HeaderSocialContainer = styled.div.attrs(props => ({
    id: "header-social-container"
}))`
    display: flex;
    flex-direction: row;
    > * {
        margin-left: 5px;
        margin-right: 5px;
        color: white;
        cursor: pointer;
        &:hover {
            color: rgba(214, 10, 10, 0.651);;
        }
    }
    @media (max-width:800px) {
        width: 100%;
        justify-content: space-between;
        > * {
            margin-right: 0;
            margin-left: 0;
        }
    }
`;

const Redirect = styled.a.attrs(props => ({
    className: "menu-header-redirect smoothscroll"
}))`
    color: ${props => props.current === 1 ? "rgba(214, 10, 10, 0.651);" : "white"};
    text-decoration: none;
    text-transform: uppercase;
    cursor: pointer;
    font-size: 11px;
    font-family: opensans-bold, sans-serif;
    font-weight: 400;
    letter-spacing: 1.5px;
    text-align: left;
    &:hover {
        color: rgba(214, 10, 10, 0.651);;
    }
    margin-left: 5px;
    margin-right: 5px;
`

const isDesktop = () => {
    const {innerWidth: width} = window;
    return width > 800;
}

const SimpleHeaderContainer = styled(Container)`
    position: relative;
    border-bottom: 1px solid black;
`;

export const SimpleHeader = () => {
    const navigate = useNavigate();

    return (
        <SimpleHeaderContainer>
            <ArrowBackIcon style={{cursor: "pointer"}} onClick={() => navigate(-1)}/>
            <Logo src={logoBlack}/>
        </SimpleHeaderContainer>
    );
};

export const OrderConfirmedHeader = () => {
    const navigate = useNavigate();

    return (
        <SimpleHeaderContainer>
            <ArrowBackIcon style={{cursor: "pointer"}} onClick={() => navigate("/")}/>
            <Logo src={logoBlack}/>
        </SimpleHeaderContainer>
    );
};

export default function Header() {

    const node = useRef();
    let [current, setCurrent] = useState("jumbotron")
    let [displayMenu, setDisplayMenu] = useState(isDesktop());

    const [prevScrollPos, setPrevScrollPos] = useState(0);
    const [visible, setVisible] = useState(true);

    const handleScroll = debounce(() => {
        // find current scroll position
        const currentScrollPos = window.pageYOffset;

        // set state based on location info (explained in more detail below)
        setVisible((prevScrollPos > currentScrollPos && prevScrollPos - currentScrollPos > 70) || currentScrollPos < 10);

        // set state to new scroll position
        setPrevScrollPos(currentScrollPos);
    }, 50);

    // new useEffect:
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);

    }, [prevScrollPos, visible, handleScroll]);


    useEffect(() => {
        if (isDesktop()) {
            return;
        }
        const handleClick = e => {
            if (node.current.contains(e.target)) {
                return;
            }
            setDisplayMenu(null);
        };
        document.addEventListener("mousedown", handleClick);
        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    }, []);

    useEffect(() => {
        if (isDesktop()) {
            return;
        }
        if (!visible) {
            setDisplayMenu(false);
        }
    }, [visible])

    return (
        <Container style={{top: visible ? '0' : '-80px'}}>
            <Redirect href={"#jumbotron"}>
                <Logo src={logo}/>
            </Redirect>
            <Menu ref={node}>
                <MenuIcon id="menu-icon" onClick={() => setDisplayMenu(!displayMenu)}/>
                {displayMenu && <IconsContainer>
                    <Redirect current={current === "jumbotron" ? 1 : 0} href="#jumbotron"
                              onClick={() => setCurrent("jumbotron")}>
                        Acasă
                    </Redirect>
                    <Redirect current={current === "about" ? 1 : 0} href="#about" onClick={() => setCurrent("about")}>
                        Despre mine
                    </Redirect>
                    <Redirect current={current === "services" ? 1 : 0} href="#services"
                              onClick={() => setCurrent("services")}>
                        Servicii
                    </Redirect>
                    <Redirect current={current === "portfolio" ? 1 : 0} href="#portfolio"
                              onClick={() => setCurrent("portfolio")}>
                        Portofoliu
                    </Redirect>
                    <Redirect current={current === "vloguri" ? 1 : 0} href="#vloguri"
                              onClick={() => setCurrent("vloguri")}>
                        Vlog / Youtube
                    </Redirect>
                    <HeaderSocialContainer>
                        <a href="https://www.facebook.com/aszabypersonaltraining" target={"_blank"} rel="noreferrer">
                            <FacebookIcon/>
                        </a>
                        <a href="https://www.instagram.com/aszaby.fitness/" target={"_blank"} rel="noreferrer">
                            <InstagramIcon/>
                        </a>
                        <a href="https://youtube.com/channel/UChwj02LtID1jmbqXkdEwBUg" target={"_blank"}
                           rel="noreferrer">
                            <YouTubeIcon/>
                        </a>
                    </HeaderSocialContainer>
                </IconsContainer>
                }
            </Menu>
        </Container>
    )
};
