import React from "react";
import GoToTop from "../common/utilities";

export default function Confidentialitaty() {
    return (
       <div>
           <p>POLITICA DE CONFIDENȚIALITATE</p>
<p></p>
<p></p>
<p>GENERALITĂȚI<span class="Apple-converted-space">&nbsp;</span></p>
<p>Regulamentul 2016/679 privind protecţia persoanelor fizice &icirc;n ceea ce priveşte prelucrarea datelor cu caracter personal şi privind libera circulaţie a acestor date (General Data Protection Regulation, &icirc;n prezentul document &ndash; GDPR, Regulament sau RGPD) a fost adoptat de Parlamentul European și Consiliul Uniunii Europene &icirc;n 27 aprilie 2016, prevederile acestuia fiind direct aplicabile &icirc;ncep&acirc;nd cu data de 25 mai 2018. Acest Regulament abrogă, &icirc;n mod expres, Directiva 95/46/CE, &icirc;nlocuind astfel și prevederile Legii nr. 677/2001 (&icirc;n prezent, abrogate).</p>
<p>Regulamentul este direct aplicabil &icirc;n toate statele membre, protej&acirc;nd drepturile tuturor persoanelor fizice aflate pe teritoriul Uniunii Europene. Din punct de vedere material, Regulamentul se aplică tuturor operatorilor care prelucrează date cu caracter personal. Regulamentul nu se aplică prelucrării datelor cu caracter personal care privesc persoane juridice şi, &icirc;n special, &icirc;ntreprinderi cu personalitate juridică, inclusiv numele şi tipul de persoană juridică şi datele de contact ale persoanei juridice.</p>
<p>Datele cu caracter personal sunt definite ca fiind orice informaţii privind o persoană fizică identificată sau identificabilă (&bdquo;persoana vizată&rdquo;); o persoană fizică identificabilă este o persoană care poate fi identificată, direct sau indirect, &icirc;n special prin referire la un element de identificare, cum ar fi un nume, un număr de identificare, date de localizare, un identificator online sau la unul sau mai multe elemente specifice, proprii identităţii sale fizice, fiziologice, genetice, psihice, economice, culturale sau sociale.</p>
<p>Prelucrarea datelor cu caracter personal presupune orice operaţiune sau set de operaţiuni efectuate asupra datelor sau asupra seturilor de date cu caracter personal, cu sau fără utilizarea de mijloace automatizate, cum ar fi colectarea, &icirc;nregistrarea, organizarea, structurarea, stocarea, adaptarea sau modificarea, extragerea, consultarea, utilizarea, divulgarea prin transmitere, diseminarea sau punerea la dispoziţie &icirc;n orice alt mod, alinierea sau combinarea, restricţionarea, ştergerea sau distrugerea.</p>
<p><span class="Apple-converted-space">&nbsp;</span></p>
<p>IDENTITATEA OPERATORULUI<span class="Apple-converted-space">&nbsp;</span></p>
<p>Av&acirc;nd &icirc;n vedere articolul 4 punctul 7 din Regulament, care definește noțiunea de &bdquo;operator&rdquo; ca fiind persoana fizică sau juridică, autoritatea publică, agenţia sau alt organism care, singur sau &icirc;mpreună cu altele, stabileşte scopurile şi mijloacele de prelucrare a datelor cu caracter personal, operatorul care prelucrează date cu caracter personal prin intermediul acestui website este Antal Szabolcs-Sandor P.F.A., cu sediul &icirc;n Reghin, Str. Stadionului, nr. 1, ap. 2, Județul Mureş, &icirc;nmatriculată la Oficiul Registrului Comerţului F26/829/2020, av&acirc;nd CUI 43045626.</p>
<p><span class="Apple-converted-space">&nbsp;</span></p>
<p>COLECTAREA DATELOR PERSONALE</p>
<p>Care sunt datele cu caracter personal colectate<span class="Apple-converted-space">&nbsp;</span></p>
<p>Operatorul acestui website colectează, stochează şi prelucrează următoarele date personale ale / referitoare la dvs.:</p>
<p><span class="Apple-tab-span"> </span>&bull;<span class="Apple-tab-span"> </span>Nume, prenume</p>
<p><span class="Apple-tab-span"> </span>&bull;<span class="Apple-tab-span"> </span>Adresa de domiciliu</p>
<p><span class="Apple-tab-span"> </span>&bull;<span class="Apple-tab-span"> </span>Date de contact (precum email, telefon, fax)</p>
<p><span class="Apple-tab-span"> </span>&bull;<span class="Apple-tab-span"> </span>V&acirc;rsta.</p>
<p><span class="Apple-converted-space">&nbsp;</span></p>
<p>OBȚINEREA CONSIMȚĂM&Acirc;NTULUI</p>
<p>Generalități<span class="Apple-converted-space">&nbsp;</span></p>
<p>Pentru ca prelucrarea datelor cu caracter personal să fie legală, GDPR prevede ca aceasta să fie efectuată &icirc;n baza unui motiv legitim, precum executarea sau &icirc;ncheierea unui contract, &icirc;ndeplinirea unei obligații legale, sau &icirc;n baza consimțăm&acirc;ntului valabil exprimat de persoana vizată &icirc;n prealabil. &Icirc;n acest din urmă caz, operatorului i se impune obligația de a putea demonstra faptul că persoana &icirc;n cauză și-a dat consimțăm&acirc;ntul pentru prelucrarea respectivă. Consimțăm&acirc;ntul exprimat sub imperiul Directivei 95/46/CE răm&acirc;ne valabil dacă acesta &icirc;ndeplinește condițiile prevăzute de GDPR.</p>
<p>Acordarea consimțăm&acirc;ntului trebuie să fie realizată printr-o declarație sau printr-o acțiune neechivocă care să constituie o manifestare liber exprimată, specifică, &icirc;n cunoştinţă de cauză şi clară a acordului persoanei vizate pentru prelucrarea datelor sale cu caracter personal. &Icirc;n cazul &icirc;n care consimțăm&acirc;ntul persoanei vizate este dat &icirc;n contextul unei declarații, &icirc;n format electronic sau &icirc;n scris, care se referă și la alte aspecte, cererea privind consimțăm&acirc;ntul trebuie să fie prezentată &icirc;ntr-o formă care o diferențiază &icirc;n mod clar de celelalte aspecte, put&acirc;nd fi realizată chiar și prin bifarea unei căsuțe. Pentru ca prelucrarea datelor cu caracter personal să fie legală, GDPR prevede ca aceasta să fie efectuată &icirc;n baza unui motiv legitim, precum executarea sau &icirc;ncheierea unui contract, &icirc;ndeplinirea unei obligații legale, sau &icirc;n baza consimțăm&acirc;ntului valabil exprimat de persoana vizată &icirc;n prealabil. &Icirc;n acest din urmă caz, operatorului i se impune obligația de a putea demonstra faptul că persoana &icirc;n cauză și-a dat consimțăm&acirc;ntul pentru prelucrarea respectivă. Consimțăm&acirc;ntul exprimat sub imperiul Directivei 95/46/CE răm&acirc;ne valabil dacă acesta &icirc;ndeplinește condițiile prevăzute de GDPR.</p>
<GoToTop/>
</div>
    );
};