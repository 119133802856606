import styled from "styled-components";
import backgroundImage from "./detatailedbackground.jpeg"
import aboutMe1 from "./aboutme1-min.jpg";
import aboutMe2 from "./aboutme2-min.jpg";
import GoToTop from "../common/utilities";

const Container = styled.div`
    
`;
const ImageContainer = styled.div`
    min-width: 100%;
    height: 700px;
    overflow: hidden; 
    display: inline-block;

    @media(max-width: 800px) {
        height: 250px;
    }
`;
const Image = styled.img`
    width: 100%;
    height: 100%;
    transition: all .2s ease;
    vertical-align: middle;
    object-fit: cover;
`;
const AboutMeContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding-left: 200px;
    padding-right: 200px;
    padding-top: 100px;
    padding-bottom: 100px;

    @media(max-width: 800px) {
        padding-left: 0;
        padding-right: 0;
        padding-top: 20px;
        padding-bottom: 20px;
    } 
`;
const DescriptionContainer = styled.div`
    & img {
        height: 400px;
        border-radius: 50%;
        @media(max-width: 1200px) {
            height: 350px;
        }
        @media(max-width: 800px) {
            width: 300px;
            height: 300px;
        }
    }
    & div {
        justify-content: center;
        display: flex;
        max-width: 800px;
        flex-direction: column;
        padding-left: 50px;
        padding-right: 50px;
        line-height: 30px;

        & h2:not(:first-child) {
            margin-top: 15px;
        }
        & h2 {
            margin-bottom: 15px;
        }
        @media(max-width: 800px) {
            font-size: 13px;
            padding-bottom: 25px;
        }
    }
    justify-content: center;
    display: flex;
    flex-direction: row;

    @media(max-width: 800px) {
        align-items: center;
        flex-direction: column-reverse;
    }
`;

const DescriptionContent = ({image, title, children}) => {
    return (
        <DescriptionContainer>
            <img src={aboutMe2} alt="description-img"/>
            <div>
                <h2>{title}</h2>
                {children}
            </div>
        </DescriptionContainer>
    );
};


export default function DetailedAboutMe() {
    return (
        <Container>
            <ImageContainer>
                <Image src={backgroundImage} />
            </ImageContainer>
            <AboutMeContainer>
                <DescriptionContent title="Despre mine" image={aboutMe1}>
                    <p>
                        Sportul, disciplina, determinarea și ambiția sunt cuvintele care mă definesc. Sportul
                        face
                        parte din rutina mea de la vârsta de 6 ani, am fost pasionat de mic de sport si
                        competiție.
                        Am practicat dans sportiv de performanță, baschet, fotbal și în cele din urmă
                        fitness/culturism. Pentru mine sănătatea a fost dintotdeauna o prioritate, fiind crescut
                        din
                        copilărie cu această gândire și pe aceste principii - pot spune chiar că sunt obsedat de
                        acest aspect, lucru care m-a condus la meseria pe care o practic.
                        Activez ca și tehnician nutriționist si antrenor personal de 6 ani, aptitudinile mele
                        fiind
                        susținute de atestări oficiale în aceste domenii, cât și de experienta dobândită de-a
                        lungul
                        anilor. Am pășit prima dată într-o sală de fitness în urmă cu 13 ani, și pot spune că a
                        fost
                        dragoste la prima vedere. Pun extrem de multă pasiune în munca pe care o fac, îmi iubesc
                        meseria, și sunt foarte bucuros când pot împărtăși cunoșințele cu cei din jurul meu –
                        cine
                        mă cunoaște, poate relata.
                    </p>
                    <p styles={{ fontWeight: 1200000 }}>
                        Rezultatul tău, satisfacția mea!
                    </p>
                
                    <p>
                        Dacă din colaborarea noastră, pleci fără rezultate satisfăcătoare, pentru mine este un
                        eșec
                        enorm. Plata și câștigul meu sunt rezultatele și zâmbetul tău de la final. Te invit să
                        consulți pagina mea de programe de antrenament, iar dacă
                        schimbarea
                        este ceea ce cauți, ești în locul potrivit. Hai să ne cunoaștem!
                    </p>
                    <h2>Despre mine – ca și sportiv</h2>
                    <p>
                        Practic culturism de performanță de 5 ani, concurând la divizia Men`s Physique.
                        Sunt multiplu campion la unele dintre cele mai importante concursuri internaționale
                        din România. Cunoștințele mele în ceea ce privește manipularea corpului/metabolismului
                        și pregătirea competițională, le învăț și aplic persoanelor dornice de a trece la un
                        nivel competitiv și a participa la un concurs de culturism & fitness.
                    </p>
                </DescriptionContent>

            </AboutMeContainer>
            <GoToTop/>
        </Container>
    );
};