import React from "react";
import GoToTop from "../common/utilities";

export default function Cookies() {
    return (
    <div>
<p>Politica de cookie-uri</p>
<p>&nbsp;</p>
<ol>
<li>1. Introducere</li>
</ol>
<p>https://antalszabyfitness.com. utilizează cookie-uri, motiv pentru care am elaborat prezenta politică prin care vă informăm cu privire la modul prin care sunt colectate informațiile dumneavoastră &icirc;n momentul &icirc;n care accesati site-ul https://antalszabyfitness.com/&nbsp;at&acirc;t cu ajutorul cookie-urilor, c&acirc;t și prin alte tehnologii de umărire, cu respectarea prevederilor legale ale Regulamentului (UE)&nbsp; nr. 679 din 27 aprilie 2016 privind protecţia persoanelor fizice &icirc;n ceea ce priveşte prelucrarea datelor cu caracter personal şi privind libera circulaţie a acestor date şi de abrogare a Directivei 95/46/CE (RGDP).</p>
<p>Cookie-urile pe care le folosim nu rețin informații cu parivie la numele dumneavoastră sau detaliile bancare. &Icirc;nsă, acestea ne oferă informații cu privire la navigarea și datele dumneavoastră cu caracter personal atunci c&acirc;nd alegeți să vă &icirc;nregistrați pentru un newsletter.</p>
<p>Conform Regulamentului European, responssabilitatea noastră permanentă este confidențialitatea informatiilor colectate. Pentru asta, vom actualize periodic prezentul document de informare, pe măsură ce vor fi &icirc;ndepliite practice noi cu privire la datele cu character personal sau se vor adopta politici noi de confidențialitate. &Icirc;n ceea ce privește prelucrarea datelor bazate pe &nbsp;bază de consimțăm&acirc;nt, vă vom notifica &icirc;n legătura cu orice modificare și vă vom solicita consimțăm&acirc;ntul dumneavoastră suplimentar.</p>
<p>Menționăm faptul că nu vom solicita și nu vom primi informații, de orice natură, de la copiii sub 16 ani.</p>
<p>&Icirc;n informațiile de mai jos vă vom prezenta informații despre plasarea, utilizarea si administrarea cookie-urilor utilizate de site-ul&nbsp;<a href="https://antalszabyfitness.com/">https://antalszabyfitness.com/</a> si vă vom menționa o serie de linkuri utile &icirc;n legătură cu acest subiect.</p>
<ol start="2">
<li>2. Contextul legislativ</li>
</ol>
<p>Conform cerințelor a Regulamentului (UE) 2016/679 privind protecția persoanelor fizice &icirc;n ceea ce privește prelucrarea datelor cu caracter personal și privind libera circulație a acestor date, avem obligația fermă de administrare, &icirc;n condiții de siguranță și numai pentru scopurile specificate, a datelor personale pe care dumneavoastră ni le furnizați prin utilizarea site-ului nostru &icirc;n acord cu prevederile Regulamentului GDPR.</p>
<p>Regulamentul GDPR face referire la drepturile persoanelor vizare prin care inserează obligativitatea de a oferi utilizatorilor posibilitatea să-și exprime sau, dupa caz, să-și retragă, oric&acirc;nd, consimțăm&acirc;ntul pentru prelucrarea datelor sale cu caracter personal, să rectifice conținutul datelor cu caracter personal sau să solicite transferul datelor cu caracter personal către un alt operator. Totodată, este imperative să informăm utilizatorul despre conținutul cookie-urilor utilizate de site-ul nostru, ce categorii de date personale ale utilizatorului sunt prelucrate de cookie-urile noastre, ce facem cu informațiile obținute de cookie-uri și unde stocăm respectivele informații, oferirea unui nivel de securitate ridicat asupra informațiilor colectate de cookie-uri, posibilitatea utilizatorului de a bloca oric&acirc;nd rularea oricărui sau a tuturor cookie-urilor de pe site-ul nostru, precum și stergerea informațiilor colectate de cookie-uri.</p>
<p>Pentru mai multe detalii privind conținutul acestor norme juridice, vă rugăm să accesați link-ul de mai jos:</p>
<p><a href="https://eur-lex.europa.eu/legal-content/RO/TXT/?uri=CELEX:32016R0679">https://eur-lex.europa.eu/legal-content/RO/TXT/?uri=CELEX%3A32016R0679</a></p>
<ol start="3">
<li>3. Drepturile dumneavoastră</li>
</ol>
<p>Regulamentul GDPR oferă anumite drepturi persoanelor vizate. Aveți dreptul de a accesa și de a rectifica (corecta) &icirc;nregistrarea datelor dumneavoastră. personale prelucrate de noi dacă acestea sunt inexacte. Ne puteți solicita să ștergem aceste date sau să &icirc;ncetam prelucrarea acestora, sub rezerva anumitor excepții. De asemenea, ne puteți solicita &icirc;ncetarea utilizării datelor dumneavoastră. &icirc;n scopuri de marketing direct. Aveți dreptul să depuneți o pl&acirc;ngere la Autoritatea &nbsp;Națională de Supraveghere a Prelucrării Datelor cu Caracter Personal. C&acirc;nd este fezabil din punct de vedere tehnic, vom putea &ndash; la cererea dumneavoastră &ndash; să vă furnizăm datele personale sau să le transmitem direct unui alt operator. Dacă accesul nu poate fi furnizat &icirc;ntr-un interval de timp rezonabil, vă vom furniza o dată la care informațiile vor fi transmise. Puteți solicita, de asemenea, informații suplimentare despre: scopul prelucrării, categoriile de date cu caracter personal vizate, care a fost sursa informațiilor și c&acirc;t timp vor fi stocate. Dacă doriți să executați oricare dintre aceste drepturi, vă rugăm să ne contactati la adresa de mail antalszaby@antalszabyfitness.com.</p>
<ol start="4">
<li>4. Consimțăm&acirc;ntul dumneavoastră pentru utilizarea cookie-urilor</li>
</ol>
<p>Prin accesarea site-ului nostru veți fi &icirc;nt&acirc;mpinat de un mesaj prin care vi se va cere consimțăm&acirc;ntul &icirc;n concordanță cu dispozițiile legale aplicabile, &icirc;n vigoare, cu privire la categoriile de cookie-uri pentru care ne dați consimțăm&acirc;ntul să le folosim pe durata vizitei dumneavoastră pe site-ul nostru. Prin consimțăm&acirc;ntul liber exprimat, &icirc;n sensul utilizarii cookie-ului/urilor respectiv/e, veți fi de acord și cu stocarea &icirc;n condiții de siguranță a informațiilor colectate de cookie-ul/-urile cu care ați fost de acord.</p>
<p>P&acirc;nă la momentul la care procesul de accept/refuz pentru utilizarea cookie-urilor nu este finalizat niciun cookie nu va fi utilizat de site-ul nostru, cu excepția cookie-urilor &icirc;n lipsa cărora site-ul nu poate funcționa.</p>
<p>Precizăm că oric&acirc;nd veți avea posibilitatea să blocați rularea cookie-urilor, dar și posibilitatea de a șterge informașiile colectate de cookie-uri.</p>
<ol start="5">
<li>5. Despre &ldquo;cookie&rdquo;?</li>
</ol>
<p>Un &ldquo;Internet Cookie&rdquo; (termen cunoscut și ca &ldquo;HTTP cookie&rdquo; &nbsp;sau &ldquo;browser cookie&rdquo;) este un fișier text de mici dimensiuni, format din litere și numere, care va fi stocat pe computerul, terminalul mobil sau alte echipamente ale utilizatorului de pe care se acceseaza acest site.</p>
<p>Cookie-ul este instalat prin solicitarea transmisă de catre un web-server unui browser (ex: Internet Explorer, Chrome, Firefox) și nu conține programe software, viruși sau spyware , neput&acirc;nd accesa informațiile aflate pe hard drive-ul utilizatorului.</p>
<p>Un cookie este format din 2 părți: numele și conținutul sau valoarea cookie-ului. Mai mult, durata de existență a unui cookie este determinată, iar tehnic doar web-serverul care a trimis cookie-ul &icirc;l poate accesa din nou &icirc;n momentul &icirc;n care un utilizator se &icirc;ntoarce pe website-ul asociat web-serverului respectiv.</p>
<p>Cookie-urile &icirc;n sine nu solicită informații cu caracter personal pentru a putea fi utilizate și, &icirc;n cele mai multe cazuri, nu identifică personal utilizatorii de internet.</p>
<p>&Icirc;n funcție de perioada de stocare după plasarea lor &icirc;n browser-ul dumneavoastrp, putem identifica 2 categorii mari de cookie-uri:</p>
<p>Cookie-uri de sesiune &ndash; sunt stocate pe o perioadă determinate de timp &icirc;n dosarul de cookie-uri al browserului web pentru ca acesta să le memoreze p&acirc;nă c&acirc;nd utilizatorul iese de pe site-ul respectiv sau &icirc;nchide fereastra browserului.</p>
<p>Cookie-uri persistente &ndash; &nbsp;sunt stocate pe hard drive-ul unui computer sau echipament pentru o durata configurată de website-ul care l-a plasat. Acest tip de cookie-uri poate fi șters de utilizator &icirc;n orice moment prin intermediul setărilor browserului.</p>
<ol start="6">
<li>6.Care sunt avantajele cookie-urilor?</li>
</ol>
<p>Un cookie conține informații care fac legătura &icirc;ntre un web-browser (browser-ul pe care &icirc;l folosiți dumneavăastră, de exemplu: Internet Explorer, Google Chrome, Safari, Mozilla Firefox, etc.) și un anume web-server, mai exact serverul care găzduiește site-ul nostru. Dacă un browser accesează acel web-server din nou, acesta poate citi informația deja stocată și poate reacționa &icirc;n consecință. Cookie-urile asigură utilizatorilor o experiență plăcuta de navigare și susțin eforturile multor website-uri pentru a oferi servicii confortabile utilizatorillor: de exemplu. Preferințele &icirc;n materie de confidențialitate online, opțiunile privind limba site-ului, coșuri de cumpărături sau publicitate relevantă.</p>
<ol start="7">
<li>7.Care este durata de viață a unui cookie?</li>
</ol>
<p>Cookieurile sunt administrate de web-servere. Durata de viață a unui cookie variază, depinz&acirc;nd de scopul pentru care este plasat. Așa cum am prezentat mai sus, cookie-urile de sesiune sunt folosite exclusiv pentru o singură sesiune și nu mai sunt reținute odată ce utilizatorul părăsește website-ul, pe c&acirc;nd cookie-urile persistente sunt reținute și refolosite de fiecare dată c&acirc;nd utilizatorul revine pe acel website. Chiar și așa, cookie-urile pot fi șterse de un utilizator &icirc;n orice moment.</p>
<ol start="8">
<li>8.Cum folosim noi cookie-urile pe acest site</li>
</ol>
<p>Am clasificat cookie-urile noastre &icirc;n următoarele categorii, pentru a vă fi mai ușor să &icirc;ntelegeți de ce avem nevoie de ele:</p>
<ul>
<li>Strict necesare &ndash; sunt utilizate pentru eficientizarea navigării pe site-ul nostru. Aceste cookie-uri nu colectează informații care ar putea fi folosite pentru publicitate sau pentru a &icirc;nregistra activitatea dumneavoastră pe internet. Fără astfel de cookie-uri, site-ul nostru nu va funcționa corect. Astfel, dacă veți bloca aceste cookie-uri, nu putem prezice cum va evolua site-ul pentru dumneavoastră.</li>
<li>Performanță &ndash; sunt utilizate pentru a analiza felul &icirc;n care site-ul nostrum funcționează și ce putem face pentru a il imbunătăți Cu titlu de exemplu precizăm: paginile pe care le accesați cel mai des și mesajele de eroare pe care le &icirc;nt&acirc;mpinați. Toate datele colectate sunt &nbsp;anonime. Utiliz&acirc;nd site-ul nostru vă exprimați acordul privind utilizarea de cookie-uri de &ldquo;performanță&rdquo;.</li>
<li>Funcționalitate &ndash; acestea ajută la &icirc;mbunătățirea experienței dumneavoastră și pot &icirc;nregistra modificările pe care le-ați făcut pe site. Informațiile pe care le colectează pot fi făcute anonime. Acestea se referă strict la site-ul nostru și nu pot urmări alte site-uri pe care le accesați. Aveți posibilitatea să blocați sau să permiteți utilizarea acestor cookie-uri, dar blocarea lor poate &icirc;nsemna că nu vom mai putea furniza anumite servicii. Este de asemenea posibil ca blocarea lor să ne impiedice să memorăm că nu ați dorit anumite informații.</li>
<li>Targeting/publicitate &ndash; acestea sunt utilizate pentru a face schimb de anumite informații cu terțe părți cu care facem reclamă, pentru a ști cum ați ajuns pe site-ul nostru. De asemenea, putem utiliza cookie-uri pentru a identifica părțile site-ului de care sunteți interesat. Utilizăm apoi aceste informații pentru a vă arăta reclame și pagini care credem că vă pot interesa, pentru a individualiza modul &icirc;n care comunicăm cu dumneavoastră sau pentru a individualiza conținutul comunicărilor pe care vi le trimitem.</li>
</ul>
<p>Dacă preferați, puteți opta pentru a renunța la acestea. Individualizarea conținutului &icirc;nseamnă că acesta include informații care reflectă interesul pe care l-ați arătat față de conținutul paginilor noastre sau oferte ori promoții care credem că v-ar putea interesa, și pentru a &icirc;mbunătăți modul &icirc;n care răspundem la necesitățile dumneavoastră</p>
<p>9.Tipurile de cookie-uri pe care noi le folosim:</p>
<p>&Icirc;n principal folosim următoarele cookies:</p>
<ul>
<li>cookie-uri pentru colectare date Facebook Ads</li>
<li>cookie-uri pentru colectare date Google Analytics</li>
</ul>
<p>Aceste cookies fac parte din grupa &ldquo;obligatorie,&rdquo;sunt folosite pentru buna funcționare a site-ului, și nu pot fi oprite fiind valabile pe &icirc;ntreaga perioadă a sesiunii.</p>
<ol start="9">
<li>10.Cum puteți opri cookie-urile?</li>
</ol>
<p>Dezactivarea și refuzul de a primi cookie-uri pot face anumite site-uri impracticabile sau dificil de vizitat sau folosit. De asemenea, refuzul de a accepta cookie-uri nu &icirc;nseamnă că nu veți mai primi/vedea publicitate online.</p>
<p>Utilizatorii &icirc;și pot configura browserul să respingă fișierele cookie sau să fie acceptate cookie-uri de la o pagină de internet anume.<br /> Toate browserele moderne oferă posibilitatea de a schimba setările cookie-urilor. Aceste setări pot fi accesate, ca regulă, &icirc;n secțiunea &bdquo;opțiuni&rdquo; sau &icirc;n meniul de &bdquo;preferințe&rdquo; al browserului tău.</p>
<p>Pentru a &icirc;nțelege aceste setări, următoarele linkuri pot fi folositoare:</p>
<p><a href="https://support.microsoft.com/en-us/kb/196955">Cookie settings in Internet Explorer</a></p>
<p><a href="https://support.google.com/chrome/answer/95647?hl=en">Cookie settings in Chrome</a></p>
<p><a href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer?redirectlocale=en-US&amp;redirectslug=Cookies">Cookie settings in Firefox</a></p>
<p><a href="https://support.apple.com/kb/PH5042?locale=en_US">Cookie settings in Safari</a></p>
<p>&nbsp;</p>
<p>Dacă aveți &icirc;ntrebări sau nelămuriri cu privire la prelucrarea informațiilor dumneavoastră sau doriți să vă exercitați drepturile legale sau aveți o altă &icirc;ngrijorare cu privire la confidențialitate, ne puteți scrie la adresa de e-mail antalszaby@antalszabyfitness.com.</p>
    <GoToTop/>
    </div>
    );
};