import React from "react";

import k1 from "./images/k1.jpg";
import k2 from "./images/k2.jpg";
import mihai1 from "./images/mihai1.jpg";
import mihai2 from "./images/mihai2.jpg";
import c1 from "./images/c1.jpg";
import c2 from "./images/c2.jpg";

import styled from "styled-components";
import backgroundImage from "./results_tall.jpg"
import SearchIcon from '@mui/icons-material/Search';
import {Link} from "react-router-dom";
import {ReactCompareSlider, ReactCompareSliderImage} from 'react-compare-slider';


const Section = styled.section`
    @media (max-width: 800px) {
        background: #454545;
        padding-left: 40px;
        padding-right: 40px;
        padding-top: 20px;
        padding-bottom: 20px;
    }
`;

const Container = styled.div.attrs(props => ({
    id: "portfolio-container"
}))`
    text-align: center;
    background: linear-gradient(rgba(255,255,255,0.2), rgba(255,255,255,0.2)), url(${backgroundImage});
    background-repeat: no-repeat;
    background-size: cover;
    @media (min-width: 800px) {
        padding-top: 40px;
        padding-bottom: 40px;
    }
    @media (max-width: 800px) {
        background-image: none;
    }

`;
const Title = styled.h2`
    text-transform: uppercase;
    padding-bottom: 20px;
    color: white;
`

const ReviewsContainer = styled.div.attrs(props => ({
    id: "portfolio-image-container"
}))`
    display: flex;
    flex-direction: column;
    padding-left: 50px;
    width: 1000px;
    @media (max-width: 800px) {
        padding: 0;
        width: 100%;
        > * {
            &:not(:last-child) {
                border-bottom: 1px solid rgba(214, 10, 10, 0.651);;
            }
        }
    }
    @media (max-width: 1000px) {
        padding: 20px;
        width: 100%;
    }
`;

export const ReviewContainer = styled.div`
    display: flex;
    flex-direction: row;    
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
    @media (min-width: 800px) {
        border-radius: 10px;
    }
    @media (max-width:800px) {
        max-width: 100%;
        margin-top: 10px;
        margin-bottom: 10px;
        flex-direction: column;
    }
`;
const ReviewContent = styled.div`
    display: flex;
    flex-direction: column;
    color: white;
    padding: 20px;
    text-align: left;
    font-size: 12px;
    line-height: 20px;
    background: black;
    opacity: .6;
    width: 100%;
    @media (max-width:800px) {
        padding-top: 20px;
        padding-bottom: 20px;
        padding-left: 20px;
        padding-right: 20px;
        background: none;
        opacity: 1;
        font-size: 14px;
        line-height: 24px;
        color: white;
    }
    & blockquote::before {
        content: open-quote;
    }
    & blockquote::after {
        content: close-quote;
    }
    & blockquote {
        quotes: "“" "”" "‘" "’";
    }
`;
const Name = styled.h2`
    padding-top: 20px;
    @media (max-width: 800px) {
        padding-top: 10px;
    }
`;

export const Review = ({ icon1, icon2, name, children, className }) => {
    return (
        <ReviewContainer className={className}>
            <ReactCompareSlider id={"slider"}
                itemOne={<ReactCompareSliderImage src={icon1} alt="Image one" />}
                itemTwo={<ReactCompareSliderImage src={icon2} alt="Image two" />}
                style={{ width: '100%', height: '70vh' }}
            />
            <ReviewContent>
                <blockquote>{children}</blockquote>
                <Name>- {name}</Name>
            </ReviewContent>
        </ReviewContainer>
    );
};
const ViewMoreContainer = styled.div`
    display: flex;
    justify-content: center;
    @media (max-width: 800px) {
        margin-top: 20px;
    }
`
const ViewMore = styled(Link)`
    color: white;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    & svg {
        margin-right: 10px;
    }
    @media (min-width: 800px) {
        background: black;
        color: rgba(214, 10, 10, 0.651);;
        width: auto;
        padding-top: 15px;
        padding-bottom: 15px;
        padding-left: 40px;
        padding-right: 40px;
        border: 3px solid rgba(214, 10, 10, 0.651);;
    }
    @media (max-width: 800px) {
        width: 100%;
        padding: 20px;
        border: 1px solid rgba(214, 10, 10, 0.651);;
        background: none;
        color: rgba(214, 10, 10, 0.651);;
    }
`;

export default function Results() {
    return (
        <Section id="portfolio">
            <Container>
                <Title>Ce zic Clientii</Title>
                <ReviewsContainer>
                    <Review icon1={k1} icon2={k2} name="Kinga">
                    Szabi pentru mine este mai mult decât un antrenor- este un mentor și un sprijin emoțional enorm, de multe ori și psiholog. Ceea ce îl face cu adevărat special este abilitatea lui de a explica concepte complexe într-un mod ușor de înțeles.

Pasiunea lui contagioasă pentru fitness m-a inspirat să încerc pregătirea pentru un concurs de fitness, cea ce m-a ajutat să dezvolt o forță mentală remarcabilă. 

El a fost un sprijin urias, m-a învățat cum să îmi planific mesele, cum să îmi calculez caloriile pentru diferite situatii si contexte, și cum să abordez antrenamentele și intensitatea / greutățile cu încredere.

Pregătirea pentru un concurs de fitness  este cu două tăișuri din punct de vedere emoțional. Pe de o parte, este foarte intensă. Te întărește mental și îți dai seama că poți sã îți depăsesti limitele pe care credeai că le ai. Apoi apar probleme legate de imaginea corpului tinzând spre perfecțiune luni întregi. În acest proces avem nevoie de un ochi exterior care ne poate readuce cu picioarele pe pământ și să ne facă să ne vedem imaginea mai clar. Un antrenor care vede lucid si real lucrurile și știe când și cum să acționeze. Un antrenor care, totodată ne poate impulsiona să lucrăm și mai tare când ne este greu. Și nu în ultimul rând un antrenor care înțelege corpul uman și are mereu "asul în mânecã" pentru a scoate cea mai bună formă din tine. El este Szaby !    
                    </Review>
                    <Review icon1={mihai1} icon2={mihai2} name="Mihai">
                    Bună ,sunt Mihai și am colaborat cu Szaby pentru un an de zile.
Un an in care mi s-a schimbat viața total,el având o contribuție  decisivă.
Noi am colaborat pentru o pregătire competițională din 01 oct 2021 până în noiembrie 2022 .
Totul a decurs conform planului ,am avut rezultate extraordinare și o transformare cum nu credeam.Prin el mi-am descoperit potențialul genetic și am descoperit culturismul.Lui i se datorează tot ce a urmat dupa.
Țin minte si acum ,că m-a văzut in vestiar și m-a intrebat de ce nu duc asta la următorul nivel pentru că potențial este mult,nu stiam eu atunci ce v a urma😅
A fost lângă mine trup și suflet pe toată durata colaborării și m-a impins la propriu spre obiectivul meu.
Vi-l recomand cu mare drag pentru pregatiri de concurs ,antrenamente personale si nu numai,rezultatele lui vorbesc si dovedesc ca este capabil❤️</Review>
                    <Review icon1={c1} icon2={c2} name="Cristi">
                    Am fost retinut la inceput in privinta online coaching-ului, am auzit despre Antal Szaby - Personal Training & Nutrition de la un prieten care facea deja antrenamente cu el si era foarte multumit. Asa ca am zis sa incerc si eu, s-a dovedit a fi cel mai bun lucru pe care l-am facut pentru ca in doar 2 luni jumate am reusit sa am rezultate foarte foarte bune ca niciodata (desi m-am mai antrenat perioade lungi de timp dar parca bateam pasul pe loc).
El este implicat in procesul meu de transformare chiar mai mult decat sunt eu, ajusteaza periodic regimul alimentar si antrenamentele pentru a avea rezultate maxime, si chiar au aparut mai repede decat ma asteptam! 😍
 </Review>
                    <ViewMoreContainer>
                        <ViewMore to="/results">
                            <SearchIcon />
                            <p>Vezi mai multe</p>
                        </ViewMore>
                    </ViewMoreContainer>
                </ReviewsContainer>
            </Container>
        </Section>
    );
}