import styled from "styled-components";
import GoToTop from "../common/utilities";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import Scena from "./images/scena.jpg";
import Logo from "./images/logo1.jpg";
import BA1 from "./images/ba1.jpg";
import BA2 from "./images/ba2.jpg";
import BA3 from "./images/ba3.jpg";
import BA4 from "./images/ba4.jpg";
import BA5 from "./images/ba5.jpg";
import BA6 from "./images/ba6.jpg";
import BA7 from "./images/ba7.jpg";

const ArticleContainer = styled.div`
    
`;
const WhatsapLink = styled.a` 
   color:#F84439;
   text-decoration: none;
   align-items: center;
   display: flex;
   font-size: 30px;
   border: 1px solid red;
   border-radius:20px;
   @media(max-width: 800px) {
       font-size: 20px;
   }
`
const TransformareaButton = styled.section` 
   color: white;
   background-color:black;
   padding:15px;
   border-radius:20px;
   border: 1px solid red;
   margin-left: auto;
   margin-right: auto;

`

const ImagesContainer = styled.section` 
   margin-left: auto;
   margin-right: auto;
   display: grid;
   rowGap: 5px;
   margin-left: auto;
   margin-right: auto;
`

const TransformareaLink = styled.a` 
   color:#F84439;
   text-decoration: none;
   align-items: center;
   display: flex;
   font-size: 20px;
   
   @media(max-width: 800px) {
       font-size: 12px;
       white-space: nowrap;
    }
`
const ImageContainer = styled.div`
   width: auto;
   height: auto;
    overflow: hidden; 
    display: inline-block;

    @media(max-width: 800px) {
    }
`;
const Image = styled.img`
    width: 100%;
    height: auto;
    transition: all .2s ease;
    vertical-align: middle;
    object-fit: cover;
`;
const ArticleContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding-left: 200px;
    padding-right: 200px;
    padding-top: 30px;
    padding-bottom: 100px;

    @media(max-width: 800px) {
        padding-left: 0;
        padding-right: 0;
        padding-top: 20px;
        padding-bottom: 20px;
    } 

`;
const ArticleDescriptionContainer = styled.div`
    & img {
        height: 550px;
        justify-content: center;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
        @media(max-width: 1200px) {
            height: 450px;
        }
        @media(max-width: 800px) {
            width: 300px;
            height: 300px;
        }
    }
    & div {
        justify-content: center;
        display: flex;
        max-width: 800px;
        flex-direction: column;
        padding-left: 50px;
        padding-right: 50px;
        line-height: 22px;

        & p{
            font-family: -apple-system, BlinkMacSystemFont, sans-serif;
        }

        & h2:not(:first-child) {
            margin-top: 20px;
        }
        & h2 {
            margin-bottom: 15px;
            font-style: bold;
            font-size: 30px;
            font-weight: 300;
            font-family: verdana;
            text-align: center;
            padding-bottom: 2px;
            line-height: 100%;
            color: #F84439;
        }
        @media(max-width: 800px) {
            font-size: 13px;
            padding-bottom: 25px;
        }
    }
    justify-content: center;
    display: flex;
    flex-direction: row;
    font: rockwell;

    @media(max-width: 800px) {
        align-items: center;
        flex-direction: column-reverse;
    }
`;

const ArticleDescriptionContent = ({title, children}) => {
    return (
        <ArticleDescriptionContainer>
            <div>
                <h2 style={{font: 'rockwell', justifyContent: 'center !important'}}>{title}</h2>
                {children}
            </div>
        </ArticleDescriptionContainer>
    );
};


export default function OnlineCoaching() {
    return (
        <ArticleContainer>
              <ImageContainer>
                <Image src={Logo} />
            </ImageContainer>
            <ArticleContentContainer>
                <ArticleDescriptionContent title="Ai ajuns până aici, ceea ce înseamnă că îți pot fi de ajutor prin una dintre opțiunile de mai jos:">
                    <p>
                    <p style={{display: 'flex', justifyContent: "center", paddingTop: "0px", paddingBottom: "1px", color:"black", fontStyle:"italic"}}>✖️ Ai avut în trecut încercări de a intra într-o formă fizică foarte bună & să o menții, fie pe cont propriu, fie lucrând cu alți antrenori / dar nu ți-a ieșit..tot ce îți dorești este să îți vezi abdomenul, să te simți bine in pielea ta, dar pare imposibil..</p>

                    <p style={{display: 'flex', justifyContent: "center", paddingTop: "0px", paddingBottom: "15px", color:"#F84439", fontWeight:"bold"}}>✅ TE POT AJUTA - în primul rând să ajungi în cea mai bună formă a vieții tale, dar să îți si menții forma cu ajutorul schimbărilor de rutină & stil de viață! </p>
                    <section style={{display:'grid', rowGap:'5px'}}>
                        <img src={BA1} style={{marginLeft: "auto", marginRight: 'auto'}}/>
                        <img src={BA2}/>
                    </section>
                    <section style={{paddingBottom:'20px'}}/>
                    <p style={{display: 'flex', justifyContent: "center", paddingTop: "10px", paddingBottom: "0px", color:"black", fontStyle:"italic"}}>✖️ Ai pornit pe drumul spre scena unei competiții de fitness, dar nu ai ajuns la o formă destul de competitivă pentru a pune mâna pe victorie - greșelile si lipsa de experiență ți-au îngreunat și încurcat drumul în pregătire, iar rezultatul nu a fost cel așteptat.</p>

                    <p style={{display: 'flex', justifyContent: "center", paddingTop: "1px", paddingBottom: "15px",  color:"#F84439", fontWeight:"bold"}}>✅ TE POT AJUTA - O pregătire competițională este plina de situații neașteptate, progres nici decum linear și ipostaze imprevizibile - capacitatea de a adapta planul la diferite situații este obligatorie - nu există un plan de bază scris pentru o pregatire competițională, fiecare persoană si corp fiind complet diferit. Informația corectă și experiența fac toată diferența aici!  
                    Am trecut la rândul meu prin toate acestea, începând de la calificări pe locul 9 și 10 la concursuri, până la pasul pe cea mai mare treaptă a podiumului - atât personal, cât și clienții mei în pregătirea lor competițională alături de mine. </p>
                    <section style={{display:'grid', rowGap:'5px'}}>
                        <img src={Scena}/>
                    </section>
                    <section style={{display:'grid', rowGap:'5px'}}/>

                    <section style={{paddingBottom:'20px'}}/>

                    <p style={{display: 'flex', justifyContent: "center", paddingTop: "1px", paddingBottom: "1px",  color:"black", fontStyle:"italic"}}>✖️ Dorești să pui masă musculară, dar tot ce acumulezi sunt kilograme în plus, care defapt sunt grăsime corporală și nu masă musculară, dar nu ști unde greșești..</p>

                    <p style={{display: 'flex', justifyContent: "center", paddingTop: "1px", paddingBottom: "15px",  color:"#F84439", fontWeight:"bold"}}>✅ TE POT AJUTA - acumularea de masă musculară de calitate se face cu un plan bine structurat, executat cu multă disciplină și consecvență de lungă durată. </p>
                    <section style={{display:'grid', rowGap:'5px'}}>
                        <img src={BA3}/>
                        <img src={BA4}/>
                    </section>
                    <section style={{paddingBottom:'20px'}}/>
                   
                    <section style={{display: 'flex', justifyContent: "center", paddingTop: "15px", paddingBottom: "15px"}}>
                        <TransformareaLink href="https://api.whatsapp.com/send?phone=40753456933&text=Salut Szaby! Sunt interesat sa incep o colaborare prin Online Coaching, pentru a face o schimbare majora. Ai putea sa ma ajuti ?" target="_blank">
                                <TransformareaButton>
                                    ÎNCEPE TRANSFORMAREA ACUM!
                                </TransformareaButton>
                        </TransformareaLink>
                    </section>

                    <section style={{paddingBottom:'20px'}}/>

                    <p style={{display: 'flex', justifyContent: "center", paddingTop: "1px", paddingBottom: "1px", color:"black", fontStyle:"italic"}}>❓DAR DE CE AI ASCULTA DE MINE..❓ Sunt destui antrenori prin fiecare colț al internetului și prin orice sală de fitness.</p>

                    <p style={{display: 'flex', justifyContent: "left", paddingTop: "1px", paddingBottom: "1px", color:"black", fontWeight:"bold"}}>👉🏼  Pentru că:</p>

                    <p style={{display: 'flex', justifyContent: "center", paddingTop: "1px", paddingBottom: "1px", color:"#F84439", fontWeight:"bold"}}>
                    1️⃣ La rândul meu am trecut și eu prin aceste procese, am încercat ce funcționează și ce nu, și mi-am dezvoltat protocoale care dau rezultate din nou și din nou și din nou, iar rezultatele mele și a celor care lucrează cu mine vorbesc de la sine ⬇️⬇️ 
                    </p>

                    <p style={{display: 'flex', justifyContent: "center", paddingTop: "1px", paddingBottom: "15px", color:"#F84439", fontWeight:"bold"}}>2️⃣ Experiență de 7 ani ca și antrenor și nutriționist și 5 ani de concurat pe scenele de culturism & fitness, m-au purtat prin tot feluri de situații, fiind forțat să învăț constant despre ceea ce fac, când și de ce o fac, acumulând cunostințe pretioase pentru viitoarele pregătiri.</p>
                    <section style={{display:'grid', rowGap:'5px'}}>
                        <img src={BA5}/>
                        <img src={BA6}/>
                        <img src={BA7}/>
                    </section>
                    <section style={{paddingBottom:'20px'}}/>

                    <h3>🫵🏼 POȚI ȘI TU SĂ ÎȚI ATINGI CELE MAI IMPOSIBILE OBIECTIVE, CA ȘI PERSOANELE DIN ACESTE POZE 🏆</h3>

                    <section style={{display: 'flex', justifyContent: "center", paddingTop: "15px", paddingBottom: "15px"}}>
                        <TransformareaLink href="https://api.whatsapp.com/send?phone=40753456933&text=Salut Szaby! Sunt interesat sa incep o colaborare prin Online Coaching, pentru a face o schimbare majora. Ai putea sa ma ajuti ?" target="_blank">
                                <TransformareaButton>
                                    ÎNCEPE TRANSFORMAREA ACUM!
                                </TransformareaButton>
                        </TransformareaLink>
                    </section>

                    <h2>OK ! ȘI CE PRESUPUNE ??</h2>

                    <h3>Monitorizare și asistență 24/7</h3>
                    <p style={{display: 'flex', justifyContent: "center", paddingTop: "1px", paddingBottom: "10px",  color:"black"}}>Pentru că rezultatele necesită timp, iar pentru cei mai mulți rutina zilnică și consecvența este o problemă atunci când este pe termen lung, sunt aici să te țin pe drumul cel bun atât moral, cât și fizic. Vei dispune de întreg suport pe toată perioada colaborării, în special motivație și coaching moral în proces.</p>

                    <h3>Antrenament personalizat</h3>
                    <p style={{display: 'flex', justifyContent: "center", paddingTop: "1px", paddingBottom: "10px",  color:"black"}}>Structura programului tău de antrenament va fi în funcție de nevoile individuale, obiective și stilul tău de viață.</p>
                    <h3>Nutriție, suplimente și rutină</h3>
                    <p style={{display: 'flex', justifyContent: "center", paddingTop: "1px", paddingBottom: "10px",  color:"black"}}>Un plan alimentar și obiceiuri alimentare individualizate și strâns legate de obiectiv și tipul de antrenament pe care îl vei urma.</p>

                    <h3>NU RATA OCAZIA DE A-ȚI ATINGE SCOPUL, CHIAR DACĂ PARE IMPOSIBIL DE FĂCUT ÎN TIMP CE CITEȘTI ACEST TEXT! </h3>
                    <p style={{display: 'flex', justifyContent: "center", paddingTop: "1px", paddingBottom: "10px",  color:"black", fontSize:'18px'}}>Programează un consult COMPLET GRATUIT de 10 minute, și hai să vorbim despre obiectivele tale, și cum te pot ajuta să ajungem la produsul final.</p>

                    </p>

                    
                </ArticleDescriptionContent>

                <div style={{display: 'flex', justifyContent: "center", paddingTop: "15px", paddingBottom: "15px"}}>
                    <WhatsapLink href="https://api.whatsapp.com/send?phone=40753456933&text=Salut Szaby! Sunt interesat sa incep o colaborare prin Online Coaching, pentru a face o schimbare majora. Ai putea sa ma ajuti ?" target="_blank">
                        <TransformareaButton>
                            <WhatsAppIcon style={{fontSize: '50px', verticalAlign: 'middle'}}/>
                            Consult 10min WhatsApp
                        </TransformareaButton>
                    </WhatsapLink>
                </div>

            </ArticleContentContainer>
            <GoToTop/>
        </ArticleContainer>
    );
};