import styled from "styled-components";
import {BrowserView, MobileView} from 'react-device-detect';
import {useState} from "react";
import "./yt-styles.css"
import YoutubeEmbed from "./YoutubeEmbed";

const VlogContainer = styled.div`
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    padding: 5px;
    position: center;
    max-width: 426;
    max-height: 240;

`

const VlogContent = styled.div`
    flex: 1 1 0%;
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    padding-right: 20px;

`;

const ProgramImage = styled.img`
   filter: drop-shadow(0 0 0.75rem ${props => props.color ? props.color : "red"})
`

const Vlog = ({img, title, price, children, color}) => {

    let [openModal, setOpenModal] = useState(false);
    return (
        <VlogContainer>
            <VlogContent>
                <h2 style={{paddingBottom:"10px", fontStyle:"italic"}}>{title}</h2>
                {children}
            </VlogContent>
            
        </VlogContainer>
    );
};

const VlogsContainer = styled.div`
    display: flex;
    flex-direction: column;
    background: black;
    padding: 50px;
    color: white;
    box-sizing: border-box;
    @media(max-width: 800px) {
        padding: 30px;
        max-width: 100%;
    }
`

export default function Vloguri() {

    return (
        <section id="vloguri">
            <VlogsContainer>
                <Vlog >
                    <MobileView>
                            <p>
                            <YoutubeEmbed embedId="q0dUe-DCbt0"/>
                            </p>
                    </MobileView>
                    <BrowserView>
                        <p>
                        <YoutubeEmbed embedId="q0dUe-DCbt0"/>
                        </p>
                    </BrowserView>
                </Vlog>
                
            </VlogsContainer>
        </section>
    )
}