import React from "react";
import styled from "styled-components";

import InfoIcon from '@mui/icons-material/Info';
import backgroundImage from "./sz_background.jpg";
import {Link} from "react-router-dom";

const Section = styled.section`
    width: 100%;
    display: flex;
    position: relative;
`;

const SectionWrapper = styled.div`
    background-image: url(${backgroundImage});
    filter: brightness(40%);
    overflow: hidden;
    background-size: cover;
    background-position: center;
    min-height: 500px;
    max-height: 1000px;
    min-width: 100%;
`;

const TextContainer = styled.div`
    position: absolute;
    top: 80px;
    left: 50px;
    right: 65%;
    line-height: 28px;
    color: #dbdbdb;
    z-index: 100;
    background-blend-mode: multiply;
    padding: 20px;
    @media (max-width: 800px) {
        top: 10px;
        left: 0;
        right: 40%;
        line-height: 20px;
    }
    @media (max-width: 1000px) {
        top: 10px;
        left: 0;
        right: 25%;
        line-height: 20px;
    }
`;
const Title = styled.h2`
    font-size: 26px;
    padding-bottom: 20px;
    text-transform: uppercase;
    @media (max-width: 800px) {
        top: 70px;
        left: 20px;
        line-height: 30px;
    }
`;
const Description = styled.p`
    font-size: 12px;
    @media (min-width: 800px) {
        font-size: 16px;
    }
`;

const MoreButton = styled(Link)`
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    margin: 0;
    margin-top: 20px;
    padding: 8px;
    width: 80%;
    max-width: 200px;
    color: white;
    text-align: center;
    text-decoration: none;
    letter-spacing: 1px;
    border: 2px solid rgba(214, 10, 10, 0.651);;
    cursor: pointer;
    color: rgba(214, 10, 10, 0.651);;
    align-items: center;
    & svg {
        margin-right: 10px;
        padding: 2px;
    }
    @media (max-width: 800px) {
        font-size: 12px;
        width: 100%;
    }
`;

export default function About() {
    return (
        <Section id="about">
            <SectionWrapper/>
            <TextContainer>
                <Title>Despre mine</Title>
                <Description>
                    Activez ca și tehnician nutriționist si antrenor personal de 5 ani, aptitudinile mele fiind
                    susținute de atestări oficiale în aceste domenii, cât și de experienta dobândită de-a lungul
                    anilor.
                    Am pășit prima dată într-o sală de fitness în urmă cu 12 ani, și pot spune că a fost dragoste la
                    prima vedere. Pun extrem de multă pasiune în munca pe care o fac, îmi iubesc meseria, și sunt
                    foarte
                    bucuros când pot împărtăși cunoșințele cu cei din jurul meu – cine mă cunoaște, poate relata.
                </Description>
                <div style={{display: "flex", justifyContent:"center"}}>
                    <MoreButton to={"/about"}>
                        <InfoIcon/>
                        <p>Află mai multe</p>
                    </MoreButton>
                </div>
            </TextContainer>
        </Section>
    );
}