import { useState } from "react";

const ChallengesSubscribed = () => {
    const [users, setUsers] = useState([])
  
    const fetchData = () => {
      fetch("https://antalszabyfitness.com/svc/challenges")
        .then(response => {
          return response.json()
        })
        .then(data => {
          setUsers(data)
        })
    }
  
    return (
      <div>
        <button onClick={fetchData}>Fetch Users</button>
        {users.length > 0 && (
                <table>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Name (Username)</th>
                        <th>Email</th>
                        <th>Age</th>
                        <th>City</th>
                    </tr>
                </thead>
                <tbody>
                    {users.map(user => (
                        <tr key={user.id}>
                            <td>{user.id}</td>
                            <td>{user.name}</td>
                            <td>
                                <a href={`mailto:${user.email}`}>
                                    {user.email}
                                </a>
                            </td>
                            <td>{user.age}</td>
                            <td>
                               {user.city}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        )}
      </div>
    )
  }
  
  export default ChallengesSubscribed